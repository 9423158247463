<template>
  <tr :class="{ 'blue-grey darken-2': processed }">
    <td>
      <a :href="'http://' + item.domain" target="_blank">{{ item.domain }}</a>
    </td>
    <td colspan="3">
      <v-form ref="form">
        <v-row align="center" class="my-0">
          <v-col cols="6" lg="7">
            <tree-search
              v-if="!processed || valid"
              v-model="model.tree_id"
              :rules="treeSearchRules"
              :disabled="!valid || processed || loading"
              mode="classification"
              inline
            />
          </v-col>
          <nv-show :header="model.effective_start_date ? 'Start Date' : ''" cols="2" no-placeholder>
            <owl-date-picker
              v-model="model.effective_start_date"
              :disabled="processed || loading"
              label="Start Date"
              icon
              clearable
            />
            <span v-if="model.effective_start_date" class="mr-1">
              {{ model.effective_start_date }}
            </span>
          </nv-show>
          <v-col cols="4" lg="3" class="d-flex justify-end align-center">
            <v-btn-toggle v-model="valid" mandatory>
              <v-btn
                v-for="validState in validStates"
                :key="validState.text"
                :value="validState.value"
                :disabled="processed || loading"
                text
                small
              >
                {{ validState.text | humanize }}
              </v-btn>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <template v-if="item.locked_by === $auth.user.email && onTime(item.lock_expires_at)">
              <save-btn v-if="!processed" :loading="loading" class="ml-3" @click="save()" />
              <done-chip v-else class="ml-2" />
            </template>
            <expire-chip v-else />
          </v-col>
        </v-row>
      </v-form>
    </td>
  </tr>
</template>

<script>
import Rules from 'bsm-validation-rules';
import TreeSearch from '@/components/mv/TreeSearch';
import ClassifyRowMixin from '@/components/cls/ClassifyRowMixin';
import OwlDatePicker from '@/components/OwlDatePicker';
import SaveBtn from '@/components/cls/SaveBtn.vue';
import DoneChip from '@/components/cls/DoneChip.vue';
import ExpireChip from '@/components/cls/ExpireChip.vue';

const rules = {
  pattern: [Rules.presence()],
  treeId: [Rules.presence()],
};

export default {
  components: { ExpireChip, DoneChip, SaveBtn, OwlDatePicker, TreeSearch },
  mixins: [ClassifyRowMixin],
  data() {
    return {
      model: {
        pattern: this.item.domain,
        tree_id: null,
        effective_start_date: null,
      },
      rules,
      valid: true,
      treeSearchRules: [],
      validStates: [
        { text: 'valid', value: true },
        { text: 'invalid', value: false },
      ],
    };
  },
  watch: {
    valid(val) {
      if (!val) {
        this.$refs.form.resetValidation();
        this.treeSearchRules = [];
      }
    },
  },
  methods: {
    save() {
      if (this.valid) {
        this.treeSearchRules = this.rules.treeId;
      }
      const url = this.valid
        ? `/domain/discoveries/${this.item.id}/rules`
        : `/domain/discoveries/${this.item.id}/rules/invalid`;
      this.submitClassify(url, {
        failure: () => {
          this.valid = false;
        },
      });
    },
  },
};
</script>
