<template>
  <nv-page header="Proposals" data-cy="hierarchy-reviews-list">
    <template slot="toolbar">
      <v-spacer />
      <filter-sheet ref="filter-sheet" @changed="setTableSettings" />
      <nv-table-search v-model="search" sync class="ml-4" />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs.filters.clearFilters()"
      >
        <proposals-filter-sheet v-if="isAll" ref="filters" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <review-row :item="item" mv @open-form="openForm" />
      </template>
    </v-data-table>
    <review-form
      :id="form.id"
      v-model="form.show"
      :namespace="form.namespace"
      link
      @saved="tableFetch"
    />
  </nv-page>
</template>

<script>
import HTTP from '@/api/owl';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import FilterSheet from '@/components/mv/proposals/FilterSheet';
import ReviewForm from '@/components/mv/proposals/ReviewForm';
import ReviewRow from '@/components/ReviewRow';
import ProposalsFilterSheet from '@/components/mv/proposals/ProposalsFilterSheet';

const headers = [
  { text: 'Type', sortable: false, value: 'kind' },
  { text: 'Name', sortable: false, value: 'name' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Created By', sortable: false, value: 'created_by' },
  { text: 'Updated At', sortable: true, value: 'updated_at' },
];

export default {
  components: { ProposalsFilterSheet, ReviewRow, ReviewForm, FilterSheet },
  mixins: [TableMixin],
  data: () => ({
    headers,
    expanded: {},
    form: {
      show: false,
      id: null,
      namespace: null,
    },
    settings: {},
    savedTableFilters: {},
    table: {
      sortBy: ['updated_at'],
      sortDesc: [true],
    },
  }),
  computed: {
    isAll() {
      return Object.keys(this.settings).length === 0;
    },
  },
  watch: {
    settings(cur, prev) {
      if (this.isAll) {
        this.tableFilters = { ...this.savedTableFilters };
      } else if (Object.keys(prev).length === 0) {
        this.savedTableFilters = { ...this.tableFilters };
        this.tableFilters = {};
      }
      this.tableFetch();
    },
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/proposals');
    },
    openForm(item) {
      this.form.id = item.id;
      this.form.namespace = item.namespace;
      this.form.show = true;
    },
    setTableSettings(settings) {
      this.settings = settings;
    },
  },
};
</script>
