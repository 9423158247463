<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="title | humanize"
      :loading="loading"
      data-cy="transfer-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <tree-search
            v-if="value"
            v-model="item.targetId"
            :rules="rules.treeId"
            mode="classification"
            label="Select Channel"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="item.reason" label="Reason" data-cy="reason" />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import TreeSearch from '@/components/mv/TreeSearch';
import Rules from 'bsm-validation-rules';

const rules = {
  treeId: [Rules.presence()],
};

export default {
  components: { TreeSearch },
  mixins: [FormMixin],
  props: {
    childId: {
      type: Number,
      required: true,
    },
    resource: {
      type: String,
      default: null,
    },
    partial: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    rules,
  }),
  computed: {
    title() {
      return `Transfer ${this.partial ? this.resource : 'record'}`;
    },
  },
  methods: {
    formSave() {
      const transferType = this.partial ? 'partial' : 'full';
      const data = { reason: this.item.reason };
      if (this.partial) {
        data.transfer_resource = this.resource;
        data.transfer_resource_ids = this.selectedIds;
      }
      return HTTP.put(
        `/children/${this.childId}/transfer/${transferType}/${this.item.targetId}`,
        data,
        { niSkipFlash: true },
      )
        .then(() => {
          this.$flash('success', `${this.title} Proposal ${this.formSuccessMsg}`);
        })
        .catch(err => {
          this.formErrorHandler(err);
        });
    },
  },
};
</script>
