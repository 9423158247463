<template>
  <nv-page header="Android Rules" data-cy="android-rules-list">
    <template #toolbar>
      <v-spacer />
      <v-btn class="mr-4" @click="testDialog.show = true">Test Pattern</v-btn>
      <nv-table-search v-model="search" sync />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <rules-filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="extendedItems"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="{ name: 'owl.android.rules.show', params: { ruleId: item.id } }">
              {{ item.pattern }}
            </router-link>
          </td>
          <td>
            <v-btn
              :href="'https://play.google.com/store/apps/details?id=' + item.pattern"
              icon
              target="_blank"
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </td>
          <td>{{ item.app_dictionary_id }}</td>
          <td>
            <router-link
              v-if="item.application"
              :to="{ name: 'owl.applications.show', params: { appId: item.app_dictionary_id } }"
              class="text-subtitle-1"
            >
              {{ item.application.name }}
            </router-link>
            <span v-else>Application unavailable</span>
          </td>
          <td>{{ item.status | humanize }}</td>
          <td>
            <updated-details :item="item" />
          </td>
          <td class="text-end">
            <v-btn v-if="$auth.hasPermissions('ant.discovery')" icon @click="openForm(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <rules-edit-form v-model="form.show" :editing="form.edit" @saved="tableFetch" />
    <test-rules-patterns v-model="testDialog.show" product="android" />
  </nv-page>
</template>

<script>
import RulesList from '@/components/cls/RulesList';
import RulesEditForm from '@/components/cls/android/RulesEditForm';
import RulesFilterSheet from '@/components/cls/android/RulesFilterSheet.vue';
import TestRulesPatterns from '@/components/cls/TestRulesPatterns.vue';
import UpdatedDetails from '@/components/cls/UpdatedDetails.vue';

const headers = [
  { text: 'Rule', sortable: false, value: 'pattern' },
  { text: 'Store', sortable: false, value: 'link' },
  { text: 'App ID', sortable: false, value: 'app_dictionary_id' },
  { text: 'Application', sortable: false, value: 'application' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Updated', sortable: false, value: 'updated_by' },
  { sortable: false, value: 'edit', align: 'end' },
];

export default {
  components: { UpdatedDetails, TestRulesPatterns, RulesFilterSheet, RulesEditForm },
  extends: RulesList('android'),
  data: () => ({
    headers,
    testDialog: { show: false },
  }),
};
</script>
