<template>
  <v-row dense align="center" justify="center">
    <v-col cols="12" sm="3">
      <country-selector v-model="filters.country" clearable />
    </v-col>
    <v-col cols="12" sm="3">
      <v-select
        ref="source"
        v-model="domainSource"
        :items="sources"
        label="Source"
        clearable
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import CountrySelector from '@/components/CountrySelector.vue';
import store from 'store';

const fns = store.namespace('filters');

export default {
  components: { CountrySelector },
  mixins: [FiltersMixin],
  data: () => ({
    sources: [
      { text: 'Desktop', value: 'rank_desktop' },
      { text: 'Mobile', value: 'rank_mobile' },
    ],
    filters: {
      country: fns.get('country'),
    },
    domainSource: fns.get('domain_source'),
  }),
  watch: {
    'filters.country': function(cur) {
      fns.set('country', cur);
    },
    domainSource(cur) {
      fns.set('domain_source', cur);
      this.$emit('update:source', cur);
    },
  },
  created() {
    this.$emit('update:source', this.domainSource);
  },
  methods: {
    clearFilters() {
      this.domainSource = null;
      this.filters.country = null;
    },
  },
};
</script>
