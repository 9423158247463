import Rules from 'bsm-validation-rules';

const rules = {
  comment: [Rules.presence(), Rules.length({ max: 255 })],
};

export function formatTitle(item) {
  return `${item?.action || ''} ${item?.item_kind || ''}`;
}

export default {
  data: () => ({
    options: [
      { text: 'Approve', value: 'approved' },
      { text: 'Reject', value: 'rejected' },
    ],
    rules,
  }),
  methods: {
    canDo(action) {
      return this.item.can && action && this.item.can.indexOf(action) !== -1;
    },
    formatTitle,
  },
};
