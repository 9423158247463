<template>
  <div v-placeholder class="pt-2" data-cy="cid-details">
    <v-chip
      v-for="item in items"
      :key="item.client_id"
      :outlined="item.status !== 'active'"
      small
      class="ma-1"
    >
      {{ item.country_code + '-' + item.client_id }}
    </v-chip>
    <v-btn
      v-if="$auth.hasPermissions('owl.marketview')"
      color="secondary"
      rounded
      small
      class="ml-1"
      type="add"
      @click="cidForm.show = true"
    >
      <v-icon>mdi-plus</v-icon>
      <span class="ml-1">Add</span>
    </v-btn>
    <new-cid-form
      v-model="cidForm.show"
      :parent-id="parentId"
      :exclude-countries="existingCountries"
      @saved="fetchData"
    />
  </div>
</template>

<script>
import HTTP from '@/api/owl';
import NewCidForm from '@/components/mv/parents/NewCidForm';

export default {
  components: { NewCidForm },
  props: {
    parentId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    items: [],
    cidForm: {
      show: false,
    },
  }),
  computed: {
    existingCountries() {
      return this.items.map(el => el.country_code);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/parents/${this.parentId}/cids`, { params: { per_page: 100 } }).then(({ data }) => {
        this.items = data;
      });
    },
  },
};
</script>
