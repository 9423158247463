<template>
  <v-row align="center" justify="center">
    <v-col class="text-center" cols="12" sm="2">
      <v-select
        v-model="filters.match_type"
        :items="matchTypes"
        clearable
        label="Match Type"
        placeholder="All"
      />
    </v-col>
    <v-col cols="12" sm="2">
      <rules-status-selector v-model="filters.status" product="ios" filter />
    </v-col>
    <v-col cols="12" sm="3" style="padding-bottom: 34px">
      <country-selector v-model="filters.country" clearable />
    </v-col>
    <v-col cols="12" sm="3">
      <updated-by-selector v-model="filters.updated_by" />
    </v-col>
  </v-row>
</template>

<script>
import HTTP from '@/api/ant';
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import RulesStatusSelector from '@/components/cls/RulesStatusSelector.vue';
import CountrySelector from '@/components/CountrySelector.vue';
import UpdatedBySelector from '@/components/cls/UpdatedBySelector.vue';

export default {
  components: { UpdatedBySelector, CountrySelector, RulesStatusSelector },
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      match_type: null,
      status: 'active',
      country: null,
      updated_by: null,
    },
    filterSheet: false,
    matchTypes: [],
    enumFetch,
  }),
  created() {
    this.enumFetch(HTTP, {
      matchTypes: '/ios/rules/enum/match_types',
    });
  },
};
</script>
