<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <template #activator="{ on }">
      <v-text-field :value="textValue" label="Category" readonly data-cy="category" v-on="on" />
    </template>
    <nv-form
      title="Select Subcategory"
      data-cy="category-form"
      @cancel="closeDialog"
      @nv-submit="save"
    >
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="selectedCategory"
            :items="categories"
            :rules="rules.category"
            item-text="name"
            item-value="id"
            label="Category"
            data-cy="categories"
            @change="fetchSubcategories"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="item"
            :items="subcategories"
            :disabled="selectedCategory == null"
            :rules="rules.subcategory"
            :loading="loading"
            validate-on-blur
            item-text="name"
            return-object
            label="Subcategory"
            data-cy="sub-categories"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import Rules from 'bsm-validation-rules';

const rules = {
  category: [Rules.presence()],
  subcategory: [Rules.presence()],
};

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    editing: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    categories: [],
    subcategories: [],
    dialog: false,
    item: {},
    selectedCategory: null,
    textValue: '',
    loading: false,
    rules,
  }),
  watch: {
    editing(val) {
      if (val) {
        this.setInitial();
      }
    },
    dialog(val) {
      if (val && this.editing && this.value !== this.item.id) {
        this.setInitial();
      }
    },
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    save(form) {
      if (form.validate()) {
        this.setTextValue();
        this.$emit('input', this.item.id);
        this.dialog = false;
      }
    },
    fetchCategories() {
      HTTP.get('/categories').then(({ data }) => {
        this.categories = data;
      });
    },
    fetchSubcategories() {
      this.item = {};
      this.loading = true;
      return HTTP.get(`/categories/${this.selectedCategory}/sub_categories`)
        .then(({ data }) => {
          this.subcategories = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setTextValue() {
      this.textValue = `${this.item.category.name} / ${this.item.name}`;
    },
    closeDialog(form) {
      this.dialog = false;
      if (form != null) {
        form.resetValidation();
      }
    },
    async setInitial() {
      this.selectedCategory = this.editing?.category?.id;
      await this.fetchSubcategories();
      this.item = { ...this.editing };
      this.setTextValue();
    },
  },
};
</script>
