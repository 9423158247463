<template>
  <v-row :align="proposed ? 'baseline' : 'end'" class="my-0">
    <nv-show cols="12" sm="3" :header="header">
      <div v-if="proposed">
        <div v-for="app in apps" :key="app.id">
          <router-link :to="{ name: 'owl.applications.show', params: { appId: app.id } }">
            {{ app.name }}
          </router-link>
        </div>
      </div>
      <span v-else>Transfer</span>
    </nv-show>
    <nv-show cols="12" sm="4" header="From">
      <tree-item :tree-id="itemId" />
    </nv-show>
    <nv-show cols="12" sm="5" header="To">
      <tree-item :tree-id="targetId" />
    </nv-show>
  </v-row>
</template>

<script>
import SEARCH from '@/api/owl-search';
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  components: { TreeItem },
  props: {
    itemId: {
      type: Number,
      required: true,
    },
    targetId: {
      type: Number,
      required: true,
    },
    proposed: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    apps: [],
  }),
  computed: {
    header() {
      return this.proposed ? `Transfer ${this.proposed.transfer_resource}` : '';
    },
  },
  created() {
    if (this.proposed != null) {
      this.fetchApps();
    }
  },
  methods: {
    fetchApps() {
      SEARCH.get('/apps/find', {
        params: {
          id: `${this.proposed.transfer_resource_ids.join(',')}`,
        },
      }).then(({ data }) => {
        this.apps = data;
      });
    },
  },
};
</script>
