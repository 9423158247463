<template>
  <v-container class="h-100" data-cy="show-hierarchy-review">
    <v-card class="pa-4 pl-7 h-100">
      <v-row class="h-100">
        <v-navigation-drawer permanent width="400">
          <v-list>
            <v-list-item v-if="parent != null" @click="setSelected(parent)">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ parent.name }}
                </v-list-item-title>
                <v-list-item-subtitle>Parent</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-group
              v-for="brand in parent.brands"
              :key="brand.id"
              no-action
              sub-group
              value="true"
              data-cy="show-brand-name"
            >
              <template #prependIcon>
                <v-icon>mdi-menu-right</v-icon>
              </template>
              <template #activator>
                <v-list-item-content @click.stop="setSelected(brand, 'Brand')">
                  <v-list-item-title>{{ brand.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="channel in brand.channels"
                :key="channel.id"
                data-cy="show-channel-name"
                @click="setSelected(channel, 'Channel')"
              >
                <v-list-item-title>{{ channel.name }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canEdit" data-cy="add-channel" @click="openForm(true, brand)">
                <v-list-item-icon>
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Add Channel</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item v-if="canEdit" data-cy="add-brand" @click="openForm()">
              <v-list-item-icon class="mx-4">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Add Brand</v-list-item-title>
            </v-list-item>
          </v-list>
          <template #append>
            <div v-if="canEdit" class="d-flex justify-space-between px-4 py-2">
              <v-btn color="error" @click="submitTree('dismissed')">Dismiss</v-btn>
              <v-btn color="secondary" data-cy="submit-hierarchy" @click="submitTree('pending')">
                Submit
              </v-btn>
            </div>
          </template>
        </v-navigation-drawer>
        <v-col ref="details">
          <v-row v-if="selected.id">
            <nv-show ref="type" cols="12" sm="5" header="Type">
              {{ selected.type }}
            </nv-show>
            <nv-show ref="name" cols="12" sm="7" header="Name">
              {{ selected.name }}
            </nv-show>
            <nv-show ref="status" cols="12" sm="5" header="Status">
              {{ selected.status | humanize }}
            </nv-show>
            <nv-show
              v-if="selected.type !== 'Parent'"
              ref="ad_supported"
              cols="12"
              sm="7"
              header="Ad Supported"
            >
              {{ selected.ad_supported | humanize }}
            </nv-show>
            <nv-show
              v-if="selected.sub_category"
              ref="category"
              cols="12"
              sm="12"
              header="Category"
            >
              {{ categoryName }}
            </nv-show>
            <nv-show v-if="selected.start_date" cols="12" sm="5" header="Start Date">
              {{ selected.start_date | date }}
            </nv-show>
          </v-row>
          <v-row>
            <v-col v-if="canEdit && isDeletable">
              <v-btn color="error" @click="deleteChild">Delete {{ selected.type }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <brand-channel-form
      v-model="form.show"
      :channel="form.isChannel"
      :post-endpoint="postEndpoint"
      :brand="form.brand"
      @saved="fetchData"
    />
  </v-container>
</template>

<script>
import HTTP from '@/api/owl';
import BrandChannelForm from '@/components/mv/BrandChannelForm';

export default {
  components: { BrandChannelForm },
  data: () => ({
    tree: {},
    parent: {},
    selected: {},
    form: {
      show: false,
      isChannel: false,
      brand: null,
    },
    postEndpoint: undefined,
  }),
  computed: {
    canEdit() {
      return this.tree.can?.indexOf('update') !== -1;
    },
    isDeletable() {
      return (
        this.selected.type === 'Channel' ||
        (this.selected.type === 'Brand' && !this.selected.channels.length)
      );
    },
    categoryName() {
      return `${this.selected.sub_category.category.name} / ${this.selected.sub_category.name}`;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/proposals/tree/${this.$route.params.treeId}`).then(({ data }) => {
        this.tree = data;
        this.parent = data.item;
        this.setSelected(data.item);
      });
    },
    openForm(isChannel = false, brand) {
      this.form.isChannel = isChannel;
      this.postEndpoint = isChannel
        ? `/proposed/brands/${brand.id}/channels`
        : `/proposed/parents/${this.parent.id}/brands`;
      this.form.brand = brand;
      this.form.show = true;
    },
    setSelected(item, type = 'Parent') {
      this.selected = {
        ...item,
        type,
      };
    },
    submitTree(status = '') {
      this.tree.status = status;
      HTTP.put(`/proposals/tree/${this.tree.id}`, this.tree).then(() => {
        var msg = 'Queued for approval';
        if (status == 'dismissed') {
          msg = 'Proposed Hierarchy has been dismissed';
        }
        this.$flash('success', msg);
        this.$router.push({ name: 'owl.search.list' });
      });
    },
    deleteChild() {
      HTTP.delete(`/proposed/${this.selected.type.toLowerCase()}s/${this.selected.id}`).then(() => {
        this.$flash('success', 'Successfully deleted');
        this.fetchData();
      });
    },
  },
};
</script>

<style>
.h-100 {
  height: 100%;
}
.container.h-100 {
  max-width: 1600px;
}
.v-list-group--sub-group.v-list-group--active
  .v-list-item__icon.v-list-group__header__prepend-icon
  .v-icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
</style>
