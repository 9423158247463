<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="domain-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            ref="pattern"
            v-model="item.pattern"
            :rules="rules.pattern"
            data-cy="pattern"
            label="Pattern"
          />
        </v-col>
        <v-col cols="12">
          <owl-date-picker v-model="item.effective_start_date" label="Start Date" clearable />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import Rules from 'bsm-validation-rules';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import OwlDatePicker from '@/components/OwlDatePicker';

export const rules = {
  pattern: [Rules.presence(), Rules.format(/^[\w-]+(\.[\w\-/?#=*$%&:\\]+)+$/)],
};

export default {
  components: { OwlDatePicker },
  mixins: [FormMixin],
  props: {
    treeId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    formContext: 'Domain',
    rules,
  }),
  methods: {
    formInit() {
      return { tree_id: this.treeId };
    },
    formSave() {
      return this.submitForm(HTTP, '/domain/rules', {
        success: ({ data }) => {
          this.$flash('success', `${data.name} domain ${this.formSuccessMsg}`);
        },
      });
    },
  },
};
</script>
