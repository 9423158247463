<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="3">
      <v-select v-model="filters.status" :items="statuses" label="Status" clearable />
    </v-col>
    <v-col cols="12" sm="3">
      <v-text-field
        ref="created_by"
        v-model="createdBy"
        :rules="rules.email"
        label="Created by"
        clearable
        append-icon="mdi-send-circle-outline"
        validate-on-blur
        @keydown.enter="onChange()"
        @click:append="onChange()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import Rules from 'bsm-validation-rules';

const rules = {
  email: [Rules.format(/.+@.+/, 'invalid email format')],
};

export default {
  mixins: [FiltersMixin],
  data: () => ({
    statuses: [
      { text: 'Pending', value: 'pending' },
      { text: 'Approved', value: 'approved' },
      { text: 'Rejected', value: 'rejected' },
    ],
    filters: {
      status: null,
      created_by: null,
    },
    createdBy: null,
    rules,
  }),
  watch: {
    createdBy(val) {
      if (!val) {
        this.filters.created_by = val;
      }
    },
    'filters.created_by': function(val) {
      if (val !== this.createdBy) {
        this.createdBy = val;
      }
    },
  },
  methods: {
    onChange() {
      this.$refs['created_by'].blur();
      if (this.createdBy && !this.$refs['created_by'].validate()) {
        return;
      }
      this.createdBy = this.createdBy?.trim();
      this.filters.created_by = this.createdBy;
    },
  },
};
</script>
