<template>
  <nv-tab-page>
    <template #toolbar>
      <v-row>
        <nv-btn v-if="$auth.hasPermissions('owl.marketview')" type="add" @click="openForm()" />
      </v-row>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>{{ item.replacement_name }}</td>
          <td>{{ item.country_code | uppercase }}</td>
          <td>{{ item.effective_start_date | date }}</td>
          <td>{{ item.effective_end_date | date }}</td>
          <td>
            <v-btn v-if="$auth.hasPermissions('owl.marketview')" icon @click="openForm(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <rules-form
      v-model="form.show"
      :editing="form.edit"
      :type="type"
      :type-id="id"
      @saved="tableFetch"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/owl';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import RulesForm from '@/components/mv/RulesForm';

const headers = [
  { text: 'Name', sortable: false, value: 'replacement_name' },
  { text: 'Country', sortable: false, value: 'country_code' },
  { text: 'Start Date', sortable: true, value: 'effective_start_date' },
  { text: 'End Date', sortable: false, value: 'effective_end_date' },
  { sortable: false, value: 'edit' },
];

const types = ['parents', 'brands', 'channels'];

export default {
  components: { RulesForm },
  mixins: [TableMixin],
  props: {
    type: {
      type: String,
      required: true,
      validator: v => types.indexOf(v) !== -1,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    headers,
    tableParam: 'local.dt',
    form: {
      show: false,
      edit: null,
    },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/${this.type}/${this.id}/localization/rules`);
    },
    openForm(item = null) {
      this.form.edit = item;
      this.form.show = true;
    },
  },
};
</script>
