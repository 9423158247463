<template>
  <tr class="elevation-3">
    <td colspan="3">
      <nv-show cols="12" sm="12" header="Rule" class="pl-0">
        {{ rule.pattern }}
      </nv-show>
    </td>
    <td colspan="4">
      <nv-show cols="12" sm="12" header="Match Type" class="pl-0">
        {{ rule.match_type | humanize }}
      </nv-show>
    </td>
  </tr>
</template>

<script>
import HTTP from '@/api/ant';

export default {
  props: {
    ruleId: { type: Number, required: true },
  },
  data: () => ({
    rule: {},
  }),
  created() {
    this.fetchRule();
  },
  methods: {
    fetchRule() {
      HTTP.get(`/ios/rules/${this.ruleId}`).then(({ data }) => {
        this.rule = data;
      });
    },
  },
};
</script>
