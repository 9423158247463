<template>
  <v-list-item-content v-if="item.name">
    <v-list-item-title>
      <router-link v-if="link" :to="showLink()">
        {{ item.name }}
      </router-link>
      <span v-else>{{ item.name }}</span>
    </v-list-item-title>
    <v-list-item-subtitle
      v-if="!!item.hierarchy"
      ref="hierarchy"
      v-placeholder
      :title="isOverflowing ? fullHierarchy() : null"
    >
      <template v-if="!!item.hierarchy.parent">
        {{ item.hierarchy.parent.name }}
      </template>
      <template v-if="!!item.hierarchy.brand">
        <v-icon small>mdi-chevron-right</v-icon>
        {{ item.hierarchy.brand.name }}
      </template>
      <template v-if="!!item.hierarchy.channel">
        <v-icon small>mdi-chevron-right</v-icon>
        {{ item.hierarchy.channel.name }}
      </template>
    </v-list-item-subtitle>
  </v-list-item-content>
  <span v-else-if="showId">{{ appId }}</span>
</template>

<script>
import SEARCH from '@/api/owl-search.js';

export default {
  props: {
    app: { type: Object, default: null },
    link: { type: Boolean, default: false },
    showId: { type: Boolean, default: false },
    appId: { type: Number, default: null },
  },
  data: () => ({
    isOverflowing: false,
    item: {},
  }),
  created() {
    if (this.app) {
      this.item = this.app;
    } else if (this.appId) {
      this.fetchApp();
    }
  },
  mounted() {
    const el = this.$refs.hierarchy;
    this.isOverflowing = el && el.scrollWidth > el.offsetWidth;
  },
  methods: {
    fetchApp() {
      SEARCH.get('/apps/find', {
        params: {
          id: this.appId,
        },
      }).then(({ data }) => {
        this.item = data[0];
      });
    },
    showLink() {
      return {
        name: `owl.applications.show`,
        params: {
          appId: this.item.id,
        },
      };
    },
    fullHierarchy() {
      let str = '';
      if (this.item.hierarchy?.parent) {
        str += `${this.item.hierarchy.parent.name} > `;
      }
      if (this.item.hierarchy?.brand) {
        str += this.item.hierarchy.brand.name;
      }
      if (this.item.hierarchy?.channel) {
        str += this.item.hierarchy.channel.name;
      }
      return str;
    },
  },
};
</script>
