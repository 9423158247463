<template>
  <v-dialog :value="value" persistent max-width="700px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="pattern"
            v-model="item.pattern"
            :rules="rules.pattern"
            :disabled="item.match_type === 'bundle_id' || item.match_type === 'exact'"
            label="Pattern"
          />
        </v-col>
        <v-col cols="12">
          <app-dict-search v-if="item.id" v-model="item.app_dictionary_id" :rules="rules.appId" />
        </v-col>
        <v-col cols="12" :sm="item.match_type === 'exact' ? '6' : '12'">
          <rules-status-selector v-model="item.status" product="ios" />
        </v-col>
        <v-col v-if="item.match_type === 'exact'" cols="12" sm="6">
          <country-selector v-model="item.country" clearable restricted />
        </v-col>
        <v-col cols="12" sm="6">
          <owl-date-picker
            ref="start-date"
            v-model="item.effective_start_date"
            label="Start Date"
            data-cy="start-date"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <owl-date-picker
            ref="end-date"
            v-model="item.effective_end_date"
            :start-date="item.effective_start_date"
            label="End Date"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';
import AppDictSearch from '@/components/mv/AppDictSearch';
import OwlDatePicker from '@/components/OwlDatePicker';
import RulesStatusSelector from '@/components/cls/RulesStatusSelector.vue';
import CountrySelector from '@/components/CountrySelector.vue';

const rules = {
  pattern: [Rules.presence()],
  appId: [Rules.presence()],
};

export default {
  components: { RulesStatusSelector, OwlDatePicker, AppDictSearch, CountrySelector },
  mixins: [FormMixin],
  data: () => ({
    formContext: 'Rule',
    rules,
  }),
  methods: {
    formSave() {
      return this.submitForm(HTTP, '/ios/rules', {
        success: ({ data }) => {
          this.$flash('success', `${data.name} rule ${this.formSuccessMsg}`);
        },
      });
    },
  },
};
</script>
