<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="3">
      <v-select v-model="filters.status" :items="statuses" label="Status" />
    </v-col>
    <v-col cols="12" sm="3">
      <updated-by-selector v-model="filters.updated_by" />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import UpdatedBySelector from '@/components/cls/UpdatedBySelector.vue';

export default {
  components: { UpdatedBySelector },
  mixins: [FiltersMixin],
  props: {
    statuses: { type: Array, required: true },
  },
  data: () => ({
    filters: {
      status: 'active',
      updated_by: null,
    },
    filtersParam: 'pattern.ft',
  }),
};
</script>
