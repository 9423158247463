<template>
  <nv-page header="Domain Reviews" data-cy="domain-reviews-list">
    <template #toolbar>
      <v-row align="center">
        <v-col cols="3">
          <bulk-approve-btn
            v-if="showSelect"
            :disabled="!selected.length"
            :loading="approveLoading"
            @click="bulkApprove()"
          />
        </v-col>
        <v-col cols="4" class="text-end">
          <filter-sheet ref="filter-sheet" @changed="setTableSettings" />
        </v-col>
        <v-col cols="5">
          <nv-table-search v-model="search" sync />
        </v-col>
      </v-row>
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        v-model="tableFilters"
        @clear="$refs.filters.clearFilters()"
      >
        <reviews-filter-sheet v-if="isAll" ref="filters" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="extendedItems"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :show-select="showSelect"
      must-sort
    >
      <template #item="{ item, isSelected, select }">
        <review-row :item="item" :show-select="showSelect" @open-form="openForm">
          <template #select>
            <v-simple-checkbox v-ripple :value="isSelected" @input="select($event)" />
          </template>
        </review-row>
      </template>
    </v-data-table>
    <cls-review-form
      v-if="form.namespace"
      :id="form.id"
      v-model="form.show"
      :namespace="form.namespace"
      @saved="tableFetch"
    />
  </nv-page>
</template>

<script>
import ReviewsList from '@/components/cls/ReviewsList';
import ReviewRow from '@/components/ReviewRow';
import FilterSheet from '@/components/mv/proposals/FilterSheet';
import ClsReviewForm from '@/components/cls/ReviewForm';
import ReviewsFilterSheet from '@/components/cls/ReviewsFilterSheet.vue';
import BulkApproveBtn from '@/components/cls/BulkApproveBtn.vue';

export default {
  components: { BulkApproveBtn, ReviewsFilterSheet, ClsReviewForm, FilterSheet, ReviewRow },
  extends: ReviewsList('domain'),
};
</script>
