<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      title="Change Status"
      data-cy="rules-status-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-select
            ref="status"
            v-model="item.status"
            :items="statuses"
            :rules="rules.status"
            label="Status"
            data-cy="status"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';

const rules = {
  status: [Rules.presence()],
};
const kinds = ['domain', 'ottm', 'video', 'android', 'ios'];

export default {
  mixins: [FormMixin],
  props: {
    ruleType: {
      type: String,
      required: true,
      validator: v => kinds.indexOf(v) !== -1,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    rules,
  }),
  methods: {
    formSave() {
      return HTTP.put(`/${this.ruleType}/rules/bulk`, this.item, { niSkipFlash: true })
        .then(() => {
          this.$flash('success', 'Status Update Proposal successfully created');
        })
        .catch(err => {
          this.formErrorHandler(err);
        });
    },
  },
};
</script>
