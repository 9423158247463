<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="rules-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            ref="name"
            v-model="item.replacement_name"
            :rules="rules.name"
            label="Name"
            counter="500"
            data-cy="name"
          />
        </v-col>
        <v-col cols="12">
          <local-country-selector v-model="item.country_code" :rules="rules.country" />
        </v-col>
        <v-col :sm="editing ? 6 : 12" cols="12">
          <owl-date-picker
            ref="start-date"
            v-model="item.effective_start_date"
            label="Start Date"
          />
        </v-col>
        <v-col v-if="editing" cols="12" sm="6">
          <owl-date-picker
            ref="end-date"
            v-model="item.effective_end_date"
            :start-date="item.effective_start_date"
            label="End Date"
            clearable
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';
import OwlDatePicker from '@/components/OwlDatePicker';
import LocalCountrySelector from '@/components/mv/LocalCountrySelector';

const rules = {
  name: [Rules.presence(), Rules.length({ max: 500 })],
  country: [Rules.presence()],
  product: [Rules.presence()],
};

const types = ['parents', 'brands', 'channels'];

export default {
  components: { LocalCountrySelector, OwlDatePicker },
  mixins: [FormMixin],
  props: {
    type: {
      type: String,
      required: true,
      validator: v => types.indexOf(v) !== -1,
    },
    typeId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    localCountries: [],
    formContext: 'Relabel',
    rules,
  }),
  methods: {
    formSave() {
      let path;
      if (this.editing != null) {
        path = '/localization/rules';
      } else {
        path = `/${this.type}/${this.typeId}/localization/rules`;
      }
      return this.submitForm(HTTP, path, {
        success: ({ data }) => {
          this.$flash(
            'success',
            `${data.replacement_name} ${this.formContext} ${this.formSuccessMsg}`,
          );
        },
      });
    },
    formInit() {
      return { product: 'dcr' };
    },
  },
};
</script>
