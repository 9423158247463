import Vue from 'vue';
import Router from 'vue-router';
import { withAuth } from '@nielsen-media/ui-toolkit-vue-api';

import ParentsShow from '@/views/mv/hierarchy/parents/Show.vue';
import BrandsShow from '@/views/mv/hierarchy/brand/Show.vue';
import ChannelsShow from '@/views/mv/hierarchy/channel/Show.vue';
import MvProposalsList from '@/views/mv/reviews/List';
import SearchList from '@/views/mv/hierarchy/search/List.vue';
import MvTreeView from '@/views/mv/reviews/Show.vue';
import ApplicationSearchList from '@/views/mv/applications/List';
import ApplicationShow from '@/views/mv/applications/Show';

import DomainsDiscoveryList from '@/views/cls/domain/discovery/List';
import DomainsRulesList from '@/views/cls/domain/rules/List';
import DomainsRulesShow from '@/views/cls/domain/rules/Show.vue';
import DomainsClassifyList from '@/views/cls/domain/classify/List';
import DomainsReviewsList from '@/views/cls/domain/reviews/List';

import VideoDiscoveryList from '@/views/cls/video/discovery/List';
import VideoRulesList from '@/views/cls/video/rules/List';
import VideoRulesShow from '@/views/cls/video/rules/Show.vue';
import VideoClassifyList from '@/views/cls/video/classify/List';
import VideoReviewsList from '@/views/cls/video/reviews/List';

import ConnectedRulesList from '@/views/cls/connected-devices/rules/List';
import ConnectedRulesShow from '@/views/cls/connected-devices/rules/Show.vue';
import ConnectedClassifyList from '@/views/cls/connected-devices/classify/List';
import ConnectedReviewsList from '@/views/cls/connected-devices/reviews/List';

import AndroidDiscoveryList from '@/views/cls/android/discovery/List';
import AndroidRulesList from '@/views/cls/android/rules/List';
import AndroidRulesShow from '@/views/cls/android/rules/Show.vue';
import AndroidClassifyList from '@/views/cls/android/classify/List';
import AndroidReviewsList from '@/views/cls/android/reviews/List';

import IOSBundleClassifyList from '@/views/cls/ios/bundle/classify/List';
import IOSBundleDiscoveryList from '@/views/cls/ios/bundle/discovery/List';
import IOSReviewsList from '@/views/cls/ios/reviews/List';
import IOSRulesList from '@/views/cls/ios/rules/List';
import IOSRulesShow from '@/views/cls/ios/rules/Show.vue';
import IOSUAClassifyList from '@/views/cls/ios/ua/classify/List';
import IOSUADiscoveryList from '@/views/cls/ios/ua/discovery/List';
import IOSUserAgentsList from '@/views/cls/ios/ua/List';

import DateSnapshot from '@/views/admin/DateSnapshot';

Vue.use(Router);

export default withAuth(
  new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      { path: '/', name: 'owl.marketview', redirect: '/mv/hierarchy' },
      { path: '/mv', redirect: '/mv/hierarchy' },
      { path: '/mv/hierarchy', name: 'owl.search.list', component: SearchList },
      { path: '/mv/applications', name: 'owl.applications.list', component: ApplicationSearchList },
      {
        path: '/mv/applications/:appId',
        name: 'owl.applications.show',
        component: ApplicationShow,
      },
      { path: '/mv/parents/:parentId', name: 'owl.parents.show', component: ParentsShow },
      { path: '/mv/brand/:childId', name: 'owl.brands.show', component: BrandsShow },
      { path: '/mv/channel/:childId', name: 'owl.channels.show', component: ChannelsShow },
      { path: '/mv/reviews', name: 'owl.mv.proposals.list', component: MvProposalsList },
      {
        path: '/mv/reviews/tree/:treeId',
        name: 'owl.mv.proposals.tree.show',
        component: MvTreeView,
      },
      {
        path: '/cls/domain/discovery',
        name: 'owl.domain.discovery.list',
        component: DomainsDiscoveryList,
      },
      {
        path: '/cls/domain/rules',
        name: 'owl.domain.rules.list',
        component: DomainsRulesList,
      },
      {
        path: '/cls/domain/rules/:ruleId',
        name: 'owl.domain.rules.show',
        component: DomainsRulesShow,
      },
      {
        path: '/cls/domain/classify',
        name: 'owl.domain.classify.list',
        component: DomainsClassifyList,
      },
      {
        path: '/cls/domain/reviews',
        name: 'owl.domain.reviews.list',
        component: DomainsReviewsList,
      },
      {
        path: '/cls/video/discovery',
        name: 'owl.video.discovery.list',
        component: VideoDiscoveryList,
      },
      {
        path: '/cls/video/rules',
        name: 'owl.video.rules.list',
        component: VideoRulesList,
      },
      {
        path: '/cls/video/rules/:ruleId',
        name: 'owl.video.rules.show',
        component: VideoRulesShow,
      },
      {
        path: '/cls/video/:domain/classify',
        name: 'owl.video.classify.list',
        component: VideoClassifyList,
      },
      {
        path: '/cls/video/reviews',
        name: 'owl.video.reviews.list',
        component: VideoReviewsList,
      },
      {
        path: '/cls/connected-devices/rules',
        name: 'owl.ottm.rules.list',
        component: ConnectedRulesList,
      },
      {
        path: '/cls/connected-devices/rules/:ruleId',
        name: 'owl.ottm.rules.show',
        component: ConnectedRulesShow,
      },
      {
        path: '/cls/connected-devices/classify',
        name: 'owl.ottm.classify.list',
        component: ConnectedClassifyList,
      },
      {
        path: '/cls/connected-devices/reviews',
        name: 'owl.ottm.reviews.list',
        component: ConnectedReviewsList,
      },
      {
        path: '/cls/android/discovery',
        name: 'owl.android.discovery.list',
        component: AndroidDiscoveryList,
      },
      {
        path: '/cls/android/rules',
        name: 'owl.android.rules.list',
        component: AndroidRulesList,
      },
      {
        path: '/cls/android/rules/:ruleId',
        name: 'owl.android.rules.show',
        component: AndroidRulesShow,
      },
      {
        path: '/cls/android/classify',
        name: 'owl.android.classify.list',
        component: AndroidClassifyList,
      },
      {
        path: '/cls/android/reviews',
        name: 'owl.android.reviews.list',
        component: AndroidReviewsList,
      },

      {
        path: '/cls/ios/bundle/discovery',
        name: 'owl.ios.bundle.discovery.list',
        component: IOSBundleDiscoveryList,
      },
      {
        path: '/cls/ios/bundle/classify',
        name: 'owl.ios.bundle.classify.list',
        component: IOSBundleClassifyList,
      },
      {
        path: '/cls/ios/ua/discovery',
        name: 'owl.ios.ua.discovery.list',
        component: IOSUADiscoveryList,
      },
      {
        path: '/cls/ios/ua/classify',
        name: 'owl.ios.ua.classify.list',
        component: IOSUAClassifyList,
      },
      {
        path: '/cls/ios/rules',
        name: 'owl.ios.rules.list',
        component: IOSRulesList,
      },
      {
        path: '/cls/ios/rules/:ruleId',
        name: 'owl.ios.rules.show',
        component: IOSRulesShow,
      },
      {
        path: '/cls/ios/reviews',
        name: 'owl.ios.reviews.list',
        component: IOSReviewsList,
      },
      {
        path: '/cls/ios/user_agents',
        name: 'owl.ios.user_agents.list',
        component: IOSUserAgentsList,
      },
      {
        path: '/admin/date',
        name: 'owl.admin.date',
        component: DateSnapshot,
      },
      { path: '*', redirect: '/mv/hierarchy' },
    ],
  }),
);
