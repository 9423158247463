<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="applications-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="name"
            v-model="item.name"
            :rules="rules.name"
            label="Name"
            counter="300"
            data-cy="name"
          />
        </v-col>
        <v-col cols="12">
          <tree-search
            v-if="item.id || !childId"
            ref="tree-search"
            v-model="item.mv_child_id"
            :rules="rules.mvChildId"
            mode="classification"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="status"
            v-model="item.status"
            :items="appStatuses"
            :rules="rules.presence"
            label="Status"
            data-cy="status"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="reporting-type"
            v-model="item.reporting_type"
            :items="reportingTypes"
            :rules="rules.presence"
            label="Reporting Type"
            data-cy="reporting-type"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="app-function"
            v-model="item.app_function"
            :items="appFunctions"
            :rules="rules.presence"
            label="App Function"
            data-cy="app-function"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="system-type"
            v-model="item.system_type"
            :items="systemTypes"
            :rules="rules.presence"
            label="System Type"
            data-cy="system-type"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="pre-installed"
            v-model="item.pre_installed"
            :items="preInstalled"
            :rules="rules.presence"
            label="Pre Installed"
            data-cy="pre-installed"
          />
        </v-col>
        <v-col v-if="value" cols="12" sm="6">
          <owl-date-picker v-model="item.start_date" label="Start Date" clearable />
        </v-col>
        <v-col v-if="editing && value" cols="12" sm="6">
          <owl-date-picker
            v-model="item.end_date"
            :start-date="item.start_date"
            label="End Date"
            clearable
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import Rules from 'bsm-validation-rules';
import { FormMixin, enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import OwlDatePicker from '@/components/OwlDatePicker';
import TreeSearch from '@/components/mv/TreeSearch';

const rules = {
  name: [Rules.presence(), Rules.length({ max: 300 })],
  presence: [Rules.presence()],
  mvChildId: [Rules.presence()],
};

export default {
  components: { OwlDatePicker, TreeSearch },
  mixins: [FormMixin],
  props: {
    childId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    formContext: 'Application',
    rules,
    appStatuses: [],
    reportingTypes: [],
    appFunctions: [],
    systemTypes: [],
    preInstalled: [],
    dateDialog: false,
    pickerDate: null,
  }),
  created() {
    this.enumFetch(HTTP, {
      appStatuses: '/app/dictionary/enum/statuses',
      reportingTypes: '/app/dictionary/enum/reporting_types',
      appFunctions: '/app/dictionary/enum/app_functions',
      systemTypes: '/app/dictionary/enum/system_types',
      preInstalled: '/app/dictionary/enum/pre_installed',
    });
  },
  methods: {
    formInit() {
      return { mv_child_id: this.childId };
    },
    formSave() {
      return this.submitForm(HTTP, '/app/dictionary', {
        success: ({ data }) => {
          this.$flash('success', `${data.name} application ${this.formSuccessMsg}`);
        },
      });
    },
    enumFetch,
  },
};
</script>
