<template>
  <v-row class="my-0">
    <nv-show cols="12" sm="7" header="Country Code">
      {{ proposed.country_code | uppercase }}
    </nv-show>
    <nv-show cols="12" sm="5" header="Parent" no-placeholder>
      <tree-item :tree-id="proposed.mv_parent_id" />
    </nv-show>
  </v-row>
</template>

<script>
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  components: { TreeItem },
  props: {
    proposed: {
      type: Object,
      required: true,
    },
  },
};
</script>
