<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="domain-rules-edit-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="pattern"
            v-model="item.pattern"
            readonly
            label="Pattern"
            :rules="rules.pattern"
          />
        </v-col>
        <v-col cols="12">
          <tree-search
            v-if="item.id && item.state === 'valid'"
            ref="tree-search"
            v-model="item.tree_id"
            :rules="rules.treeId"
            mode="classification"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <rules-status-selector v-model="item.status" product="domain" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            ref="state"
            v-model="item.state"
            :items="states"
            :rules="rules.state"
            label="State"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <owl-date-picker
            ref="start-date"
            v-model="item.effective_start_date"
            label="Start Date"
            data-cy="start-date"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <owl-date-picker
            ref="end-date"
            v-model="item.effective_end_date"
            :start-date="item.effective_start_date"
            label="End Date"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';
import TreeSearch from '@/components/mv/TreeSearch';
import OwlDatePicker from '@/components/OwlDatePicker';
import RulesStatusSelector from '@/components/cls/RulesStatusSelector.vue';

const rules = {
  pattern: [Rules.presence()],
  state: [Rules.presence()],
  treeId: [Rules.presence()],
};

export default {
  components: { RulesStatusSelector, OwlDatePicker, TreeSearch },
  mixins: [FormMixin],
  props: {
    states: { type: Array, required: true },
  },
  data: () => ({
    formContext: 'Rule',
    rules,
  }),
  methods: {
    formSave() {
      return this.submitForm(HTTP, '/domain/rules', {
        success: ({ data }) => {
          this.$flash('success', `${data.name} ${this.formContext} ${this.formSuccessMsg}`);
        },
      });
    },
  },
};
</script>
