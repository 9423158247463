<template>
  <nv-tab-page>
    <template #toolbar>
      <v-row>
        <nv-btn
          v-if="$auth.hasPermissions('owl.marketview') && classifiable"
          type="add"
          data-cy="add-app-btn"
          @click="openForm()"
        />
        <v-btn
          v-if="$auth.hasPermissions('owl.marketview')"
          :disabled="!selected.length"
          color="accent"
          class="ml-3"
          data-cy="apps-transfer-btn"
          @click="openTransferForm()"
        >
          <v-icon class="mr-1">mdi-transfer</v-icon>
          <span>Transfer</span>
        </v-btn>
      </v-row>
    </template>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      show-select
      class="elevation-1"
      must-sort
    >
      <template #item="{ item, isSelected, select }">
        <tr :class="{ 'v-data-table__selected': isSelected }">
          <td>
            <v-simple-checkbox v-ripple :value="isSelected" @input="select($event)" />
          </td>
          <td>
            <router-link
              v-if="$auth.hasPermissions('owl.marketview.readonly')"
              :to="applicationPath(item)"
            >
              {{ item.name }}
            </router-link>
            <span v-else>{{ item.name }}</span>
          </td>
          <td>{{ item.status | humanize }}</td>
          <td>{{ item.reporting_type | humanize }}</td>
          <td>{{ item.app_function | humanize }}</td>
          <td>{{ item.system_type | humanize }}</td>
          <td>{{ item.pre_installed | humanize }}</td>
          <td>
            <v-btn v-if="$auth.hasPermissions('owl.marketview')" icon @click="openForm(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <apps-dictionary-form
      v-model="form.show"
      :editing="form.edit"
      :child-id="childId"
      @saved="saved"
    />
    <transfer-form
      v-model="transferForm.show"
      :child-id="childId"
      :selected-ids="transferForm.selectedIds"
      resource="applications"
      partial
      @saved="saved"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/owl';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import AppsDictionaryForm from '@/components/mv/AppsDictionaryForm';
import TransferForm from '@/components/mv/TransferForm';

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Reporting type', sortable: false, value: 'reporting_type' },
  { text: 'App Function', sortable: false, value: 'app_function' },
  { text: 'System type', sortable: false, value: 'system_type' },
  { text: 'Pre installed', sortable: false, value: 'pre_installed' },
  { sortable: false, value: 'edit' },
];

export default {
  components: { TransferForm, AppsDictionaryForm },
  mixins: [TableMixin],
  props: {
    childId: {
      type: Number,
      required: true,
    },
    classifiable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers,
      form: {
        show: false,
        edit: null,
      },
      tableParam: 'apps.dt',
      tableFilters: {
        mv_child_id: this.childId,
      },
      transferForm: {
        show: false,
        selectedIds: [],
      },
      selected: [],
    };
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/app/dictionary');
    },
    openForm(item = null) {
      this.form.show = true;
      this.form.edit = item;
    },
    applicationPath(app = null) {
      return {
        name: `owl.applications.show`,
        params: {
          appId: app.id,
        },
      };
    },
    openTransferForm() {
      this.transferForm.selectedIds = this.selected.map(el => el.id);
      this.transferForm.show = true;
    },
    saved() {
      this.selected = [];
      this.tableFetch();
    },
  },
};
</script>
