<template>
  <v-col>
    <v-row align="start" no-gutters>
      <v-col cols="4">
        <v-text-field
          ref="find"
          v-model.number="treeId"
          :rules="rules.find"
          :loading="loading"
          :disabled="loading"
          label="Find by Tree ID"
          flat
          solo
          hide-details="auto"
          append-icon="mdi-send-circle-outline"
          autocomplete="off"
          data-cy="find-box"
          @click:append="findTree"
          @keydown.enter="findTree"
        />
      </v-col>
      <v-col cols="8" class="mt-n2 pl-4">
        <v-list-item v-if="tree" data-cy="find-result">
          <search-list-avatar :type="tree.type" />
          <search-list-content :item="tree" link />
        </v-list-item>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import HTTP from '@/api/owl-search';
import Rules from 'bsm-validation-rules';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import SearchListContent from '@/components/mv/SearchListContent';

const rules = {
  find: [Rules.typeOf('integer'), Rules.numericality({ min: 0 })],
};

export default {
  components: { SearchListContent, SearchListAvatar },
  data: () => ({
    treeId: null,
    tree: null,
    loading: false,
    rules,
  }),
  methods: {
    findTree() {
      if (!this.$refs.find.validate()) {
        return;
      }
      if (!this.treeId) {
        this.tree = null;
        return;
      }
      this.loading = true;
      HTTP.get('/tree/find', { params: { id: this.treeId } })
        .then(({ data }) => {
          if (data[0] == null) {
            this.$flash('error', `${this.treeId} ID is unavailable`);
          } else {
            this.tree = data[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
