<template>
  <tr>
    <td>
      <a :href="'http://' + item.domain" target="_blank">{{ item.domain }}</a>
    </td>
    <td>{{ item.hits | number }}</td>
    <td>{{ item.last_seen_on | date }}</td>
    <td class="text-end py-3">
      <nv-btn
        v-if="$auth.hasPermissions('ant.discovery')"
        :loading="loading"
        type="lock"
        data-cy="lock-btn"
        @click="lock(item.domain)"
      />
    </td>
  </tr>
</template>

<script>
import HTTP from '@/api/ant';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    lock(domain) {
      this.loading = true;
      HTTP.put(`/video/domains/${domain}/lock`)
        .then(({ data }) => {
          if (data.count > 0) {
            this.$flash('success', `Successfully locked ${domain} video discoveries`);
            this.$router.push({ name: 'owl.video.classify.list', params: { domain } });
          } else {
            this.$flash('warning', `items were not locked`);
          }
        })
        .catch(err => {
          this.$flash('error', err.toString());
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
