<template>
  <nv-page header="Android Rule">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn v-if="$auth.hasPermissions('ant.discovery')" type="edit" @click="openForm(item)" />
    </template>
    <v-card class="pa-4" data-cy="android-rule-details">
      <v-row>
        <nv-show cols="12" sm="5" header="Rule">
          <a
            :href="'https://play.google.com/store/apps/details?id=' + item.pattern"
            target="_blank"
            class="text-subtitle-1"
          >
            {{ item.pattern }}
          </a>
        </nv-show>
        <nv-show cols="12" sm="3" header="Application">
          <router-link
            v-if="application"
            :to="{ name: 'owl.applications.show', params: { appId: item.app_dictionary_id } }"
            class="text-subtitle-1"
          >
            {{ application.name }}
          </router-link>
          <span v-else>{{ item.app_dictionary_id }}</span>
        </nv-show>
        <nv-show cols="12" sm="3" header="Updated By">{{ item.updated_by }}</nv-show>
        <nv-show cols="12" sm="1" header="Status">{{ item.status | humanize }}</nv-show>
      </v-row>
    </v-card>
    <v-card class="mt-4 elevation-2" data-cy="tabs-card">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="history-tab">
          History
        </v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id != null" v-model="tabs">
        <v-tab-item>
          <rules-history-tab
            :id="item.id"
            :key="JSON.stringify(item)"
            product="android"
            data-cy="history-pane"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <rules-edit-form v-model="form.show" :editing="form.edit" @saved="fetchData" />
  </nv-page>
</template>

<script>
import HTTP from '@/api/ant.js';
import SEARCH from '@/api/owl-search.js';
import RulesHistoryTab from '@/components/cls/RulesHistoryTab.vue';
import RulesEditForm from '@/components/cls/android/RulesEditForm.vue';

export default {
  components: { RulesEditForm, RulesHistoryTab },
  data: () => ({
    item: {},
    tabs: 0,
    form: {
      show: false,
      edit: null,
    },
    application: null,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/android/rules/${this.$route.params.ruleId}`).then(({ data }) => {
        this.item = data;
        if (data) {
          SEARCH.get('/apps/find', {
            params: {
              id: data.app_dictionary_id,
            },
          }).then(res => {
            this.application = res.data[0];
          });
        }
      });
    },
    openForm(edit) {
      this.form.edit = edit;
      this.form.show = true;
    },
  },
};
</script>
