export default {
  data: () => ({
    items: [],
    loading: false,
  }),
  watch: {
    '$route.query': {
      handler(val) {
        if (val.search) {
          this.fetchData();
        } else {
          this.items = [];
        }
      },
    },
  },
  created() {
    if (this.search) {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {},
  },
};
