<template>
  <nv-page header="Classify IOS User Agent" data-cy="ios-ua-classify">
    <template #toolbar>
      <v-btn
        v-if="$auth.hasPermissions('owl.marketview')"
        :disabled="!selected.length"
        color="accent"
        @click="bulkClassify"
      >
        <v-icon class="mr-1">mdi-format-list-checks</v-icon>
        <span>Classify</span>
      </v-btn>
      <v-spacer />
      <v-chip v-if="items.length" label outlined>
        Lock expires at {{ items[0].lock_expires_at | time('short') }}
      </v-chip>
      <v-spacer />
      <v-btn
        v-if="$auth.hasPermissions('owl.marketview')"
        color="secondary"
        @click="createForm.show = true"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>
        <span>Create App</span>
      </v-btn>
      <nv-btn v-if="$auth.hasPermissions('ant.discovery')" type="unlock" @click="unlock()" />
    </template>

    <template #filters>
      <nv-table-filters ref="applied-filters" v-model="tableFilters" no-clear />
    </template>

    <v-data-table
      v-model="selected"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :headers-length="5"
      :expanded.sync="expanded"
      hide-default-header
      show-expand
    >
      <template #header="{ props, on }">
        <thead>
          <tr>
            <th colspan="6">
              <v-row>
                <v-col cols="3" class="d-flex align-center">
                  <v-simple-checkbox
                    v-ripple
                    :value="props.everyItem"
                    :indeterminate="props.someItems && !props.everyItem"
                    class="mr-4"
                    @input="on['toggle-select-all']"
                  />
                  <span>User Agent</span>
                </v-col>
                <v-col cols="2">Domain</v-col>
                <v-col cols="3" lg="2">Application</v-col>
                <v-col cols="1" lg="2">App Name</v-col>
                <v-col cols="1">Countries</v-col>
              </v-row>
            </th>
          </tr>
        </thead>
      </template>
      <template #item="{ item, isSelected, select }">
        <classify-row
          :key="item.id"
          :item="item"
          :is-selected="isSelected"
          type="ua"
          @saved="saved"
        >
          <template #select="{ selectable }">
            <v-simple-checkbox
              v-ripple
              :value="isSelected"
              :disabled="!selectable"
              class="mr-4"
              @input="select($event)"
            />
          </template>
        </classify-row>
      </template>
    </v-data-table>
    <apps-dictionary-form v-model="createForm.show" />
    <bulk-classify-form
      v-model="bulkForm.show"
      :selected-ids="bulkForm.selectedIds"
      @saved="saved"
    />
  </nv-page>
</template>

<script>
import ClassifyList from '@/components/cls/ClassifyList';
import ClassifyRow from '@/components/cls/ios/ClassifyRow';
import AppsDictionaryForm from '@/components/mv/AppsDictionaryForm';
import BulkClassifyForm from '@/components/cls/ios/BulkClassifyForm';

export default {
  components: {
    BulkClassifyForm,
    AppsDictionaryForm,
    ClassifyRow,
  },
  extends: ClassifyList('ios/ua'),
  data: () => ({
    expanded: [],
    selected: [],
    bulkForm: {
      show: false,
      selectedIds: [],
    },
    testPatternDialog: {
      show: false,
      domain: '',
      pattern: '',
    },
  }),
  methods: {
    bulkClassify() {
      this.bulkForm.selectedIds = this.selected.map(el => el.id);
      this.bulkForm.show = true;
    },
    saved() {
      this.selected = [];
      this.tableFetch();
    },
  },
};
</script>
