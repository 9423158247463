<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Attribute</th>
        <th>Previous</th>
        <th></th>
        <th>Proposal</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(val, key) in proposal.previous_attributes" :key="key">
        <th>{{ key | humanize }}</th>
        <td>
          <tree-item v-if="key === treeEntityType && prevTree" :item="prevTree" />
          <category-show v-else-if="key === 'sub_category_id'" :subcategory-id="val" />
          <kbd v-else>{{ val + '' }}</kbd>
        </td>
        <td>
          <v-icon dense>mdi-arrow-right</v-icon>
        </td>
        <td>
          <tree-item v-if="key === treeEntityType && propTree" :item="propTree" />
          <category-show
            v-else-if="key === 'sub_category_id'"
            :subcategory-id="proposal.proposed_attributes[key]"
          />
          <kbd v-else>{{ proposal.proposed_attributes[key] + '' }}</kbd>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import SEARCH from '@/api/owl-search';
import CategoryShow from '@/components/mv/proposals/CategoryShow';
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  components: { TreeItem, CategoryShow },
  props: {
    proposal: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    treeItems: [],
  }),
  computed: {
    treeEntityType() {
      switch (this.proposal.item_kind) {
        case 'channel':
          return 'brand_id';
        case 'brand':
          return 'parent_id';
        case 'application':
          return 'mv_child_id';
        case 'c6':
          return 'mv_child_id';
        default:
          return 'tree_id';
      }
    },
    prevTree() {
      return this.treeItems.find(
        el => el.id === this.proposal.previous_attributes[this.treeEntityType],
      );
    },
    propTree() {
      return this.treeItems.find(
        el => el.id === this.proposal.proposed_attributes[this.treeEntityType],
      );
    },
  },
  created() {
    if (
      Object.prototype.hasOwnProperty.call(this.proposal.proposed_attributes, this.treeEntityType)
    ) {
      this.fetchTree();
    }
  },
  methods: {
    fetchTree() {
      SEARCH.get('/tree/find', {
        params: {
          id: `${this.proposal.previous_attributes[this.treeEntityType]},${
            this.proposal.proposed_attributes[this.treeEntityType]
          }`,
        },
      }).then(({ data }) => {
        this.treeItems = data;
      });
    },
  },
};
</script>
