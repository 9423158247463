<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :loading="loading"
      title="Create Rules"
      data-cy="bulk-ua-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <app-dict-search
            v-if="value"
            v-model="item.app_dictionary_id"
            :rules="rules.application"
          />
        </v-col>
        <v-col cols="12">
          <owl-date-picker v-model="item.effective_start_date" label="Start Date" />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import AppDictSearch from '@/components/mv/AppDictSearch';
import OwlDatePicker from '@/components/OwlDatePicker';
import Rules from 'bsm-validation-rules';

const rules = {
  application: [Rules.presence()],
};

export default {
  components: { OwlDatePicker, AppDictSearch },
  mixins: [FormMixin],
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    rules,
  }),
  methods: {
    formInit() {
      return {
        discovery_ids: this.selectedIds,
        app_dictionary_id: null,
      };
    },
    formSave() {
      return this.submitForm(HTTP, '/ios/ua/discoveries/rules/bulk', {
        success() {
          this.$flash('success', `Create Rule Proposals successfully created`);
        },
      });
    },
  },
};
</script>
