<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="cidForm"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col>
          <country-selector
            v-model="cid.country_code"
            :except="excludeCountries"
            :rules="rules.countryCode"
            path="/countries/cid"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import CountrySelector from '@/components/CountrySelector.vue';
import Rules from 'bsm-validation-rules';

const rules = {
  countryCode: [Rules.presence()],
};

export default {
  components: { CountrySelector },
  mixins: [FormMixin],
  props: {
    parentId: {
      type: Number,
      required: true,
    },
    excludeCountries: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    rules,
    cid: {
      country_code: null,
    },
    formContext: 'Client ID',
  }),
  methods: {
    formSave() {
      return this.submitForm(HTTP, `/parents/${this.parentId}/cid`, {
        data: this.cid,
        success: () => {
          this.$flash('success', `Client ID ${this.formSuccessMsg}`);
        },
      });
    },
  },
};
</script>
