<template>
  <v-row dense align="center" justify="center">
    <v-col class="text-center" cols="12" sm="3">
      <domain-filter-field v-model="filters.domain" />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import DomainFilterField from '@/components/cls/ios/DomainFilterField.vue';

export default {
  components: { DomainFilterField },
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      domain: null,
    },
    filtersParam: 'ua.ft',
  }),
};
</script>
