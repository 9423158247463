<template>
  <nv-page header="Classify Video" data-cy="video-classify">
    <template #toolbar>
      <v-spacer />
      <v-chip v-if="items.length" label outlined>
        Lock expires at {{ domain.lock_expires_at | time('short') }}
      </v-chip>
      <v-spacer />
      <v-btn
        v-if="$auth.hasPermissions('owl.marketview')"
        color="secondary"
        @click="createForm.show = true"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>
        <span>Create Channel</span>
      </v-btn>
      <v-btn @click="replaceForm.show = true">
        <v-icon class="mr-1">mdi-find-replace</v-icon>
        Replace
      </v-btn>
      <nv-btn v-if="$auth.hasPermissions('ant.discovery')" type="unlock" @click="unlock()" />
      <nv-table-search v-model="search" data-cy="search" sync />
    </template>

    <template #filters>
      <nv-table-filters ref="applied-filters" :value="displayFilters" no-clear />
    </template>

    <v-data-table
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :headers-length="7"
      :expanded.sync="expanded"
      hide-default-header
      show-expand
      single-expand
    >
      <template #header>
        <thead class="v-data-table-header">
          <tr>
            <th colspan="7">
              <v-row dense>
                <v-col cols="1" class="text-center">Video</v-col>
                <v-col cols="3">Site URL</v-col>
                <v-col cols="3">Pattern</v-col>
                <v-col cols="3">Tree ID</v-col>
                <v-col cols="1">Property</v-col>
              </v-row>
            </th>
          </tr>
        </thead>
      </template>
      <template #item="{ item, expand, isExpanded }">
        <classify-row
          :key="item.id"
          :item="item"
          :recent-items="recentItems"
          :is-expanded="isExpanded"
          :locked-by="domain.locked_by"
          :lock-expires-at="domain.lock_expires_at"
          @tree-select="saveRecentItems"
          @expand="expand($event)"
          @saved="tableFetch"
        />
      </template>
      <template #expanded-item="{ item }">
        <tr :key="item.media_url" class="elevation-3">
          <td colspan="7">
            <v-row class="mt-0">
              <nv-show cols="12" sm="6" header="Media URL" class="text-truncate">
                <a :href="item.media_url" target="_blank">{{ item.media_url | truncate(100) }}</a>
              </nv-show>
              <nv-show cols="12" sm="6" header="Site URLs">
                <div v-for="url in item.site_urls" :key="url" class="text-truncate">
                  <a :href="url" target="_blank">{{ url | truncate(100) }}</a>
                </div>
              </nv-show>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <replace-form v-model="replaceForm.show" :items="items" @replace="replace" />
    <brand-channel-form v-model="createForm.show" channel cls />
  </nv-page>
</template>

<script>
import ClassifyRow from '@/components/cls/video/ClassifyRow';
import ClassifyList from '@/components/cls/ClassifyList';
import ReplaceForm from '@/components/cls/video/ReplaceForm';
import BrandChannelForm from '@/components/mv/BrandChannelForm';
import HTTP from '@/api/ant';

export default {
  components: { BrandChannelForm, ReplaceForm, ClassifyRow },
  extends: ClassifyList('video'),
  data: () => ({
    recentItems: [],
    replaceForm: {
      show: false,
    },
    expanded: [],
    table: {
      sortBy: ['media_url'],
      sortDesc: [false],
    },
    domain: {},
  }),
  computed: {
    displayFilters() {
      return {
        ...this.tableFilters,
        domain: this.$route.params.domain,
      };
    },
  },
  created() {
    this.fetchDomain();
  },
  methods: {
    fetchDomain() {
      HTTP.get('/video/domains', {
        params: { filter: { status: 'locked' } },
      }).then(({ data }) => {
        if (data.length) {
          this.domain = data[0];
        }
      });
    },
    saveRecentItems(item) {
      const i = this.recentItems.findIndex(el => el.id === item.id);
      if (i > 0) {
        this.recentItems.splice(i, 1);
      }
      if (i !== 0) {
        let n = this.recentItems.unshift(item);
        if (n > 5) {
          this.recentItems.pop();
        }
      }
    },
    replace(f, r, selected) {
      this.items = this.items.map(item => {
        return {
          ...item,
          media_url: selected.includes(item.id) ? item.media_url.replace(f, r) : item.media_url,
        };
      });
    },
  },
};
</script>

<style>
tr.elevation-3 td {
  border-bottom: none !important;
}
</style>
