<template>
  <v-container :class="{ 'is-blank': !items.length }" class="nv-page owl-search">
    <v-row no-gutters>
      <v-toolbar class="nv-page-toolbar align-center" flat extension-height="64">
        <v-toolbar-title>Applications</v-toolbar-title>
        <template #extension>
          <nv-table-search v-model="search" />
        </template>
      </v-toolbar>
    </v-row>
    <v-progress-linear :active="loading" indeterminate />
    <v-card v-if="items.length" class="mt-1">
      <v-simple-table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Application ID</th>
            <th>Hierarchy</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td>
              <router-link :to="{ name: 'owl.applications.show', params: { appId: item.id } }">
                {{ item.name }}
              </router-link>
              <div
                v-if="item.translations && item.translations.length > 0"
                title="Translations"
                class="text-caption"
              >
                Relabel: {{ stringifyTranslations(item.translations) }}
              </div>
            </td>
            <td>{{ item.id }}</td>
            <td v-if="item.hierarchy">
              <router-link :to="hierarchyLink('parent', item)">
                <span>{{ item.hierarchy.parent.name }}</span>
              </router-link>
              <span class="mx-1">/</span>
              <router-link :to="hierarchyLink('brand', item)">
                <span>{{ item.hierarchy.brand.name }}</span>
              </router-link>
              <template v-if="item.hierarchy.channel">
                <span class="mx-1">/</span>
                <router-link :to="hierarchyLink('channel', item)">
                  <span>{{ item.hierarchy.channel.name }}</span>
                </router-link>
              </template>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import HTTP from '@/api/owl-search';
import { SearchMixin } from '@nielsen-media/ui-toolkit-vue';
import owlSearchMixin from '@/helpers/owlSearchMixin';

export default {
  mixins: [SearchMixin, owlSearchMixin],
  methods: {
    fetchData() {
      this.loading = true;
      const params = {
        q: this.search,
      };
      HTTP.get('/apps/search', { params })
        .then(({ data }) => {
          if (params.q === this.search) {
            this.items = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hierarchyLink(kind, item) {
      const param = kind === 'parent' ? 'parentId' : 'childId';
      return {
        name: `owl.${kind}s.show`,
        params: { [param]: item.hierarchy[kind].id },
      };
    },
    stringifyTranslations(translations) {
      return translations.map(t => `${t.name} (${t.country_code})`).join(', ');
    },
  },
};
</script>
