<template>
  <v-dialog :value="value" persistent max-width="1100px">
    <nv-form
      :save-disabled="!selected.length"
      title="Find And Replace"
      save-text="Replace"
      data-cy="replace-form"
      @cancel="close"
      @nv-submit="submit"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="find" label="Find" rows="1" auto-grow hide-details />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="replace"
            :disabled="isWildcard"
            label="Replace"
            rows="1"
            auto-grow
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :items="foundItems"
            :headers="headers"
            :search="search"
            :items-per-page="50"
            height="300"
            hide-default-footer
            show-select
            class="elevation-2"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Found Records</v-toolbar-title>
                <v-spacer />
                <nv-table-search v-model="search" />
              </v-toolbar>
            </template>
            <template #item.after="{ item }">
              <span v-if="replace">
                {{ item.pattern.replace(regexp(), replace) }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
const headers = [
  { text: 'Pattern', sortable: true, value: 'pattern' },
  { text: 'Replaced', sortable: false, value: 'after' },
];

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    find: '',
    replace: '',
    selected: [],
    search: '',
    headers,
  }),
  computed: {
    isWildcard() {
      return this.find.indexOf('*') > 0;
    },
    foundItems() {
      return this.items
        .map(el => {
          return { ...el, pattern: el.media_url.replace(/^\w*:\/\//, '') };
        })
        .filter(el => this.find && this.regexp().test(el.media_url));
    },
  },
  watch: {
    find(val) {
      this.replace = this.isWildcard ? val : '';
      this.search = '';
      this.selected = [];
    },
  },
  methods: {
    regexp() {
      const v = this.find.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
      if (this.isWildcard) {
        const w = v.replace(/\\\*/g, '.+');
        return new RegExp(w);
      } else {
        return new RegExp(v);
      }
    },
    submit() {
      const selectedIds = this.selected.map(el => el.id);
      this.$emit('replace', this.regexp(), this.replace, selectedIds);
      this.close();
    },
    close() {
      this.find = '';
      this.replace = '';
      this.search = '';
      this.$emit('input', false);
    },
  },
};
</script>
