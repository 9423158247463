import HTTP from '@/api/ant';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import SearchListContent from '@/components/mv/SearchListContent';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import FilterSheet from '@/components/cls/FilterSheet';
import { fetchTreeSearch, fetchAppsSearch } from '@/helpers/owlFind.js';

export default function(type) {
  return {
    components: { SearchListContent, SearchListAvatar, FilterSheet },
    mixins: [TableMixin],
    data: () => ({
      countries: [],
      extendedItems: [],
      form: {
        show: false,
        edit: null,
      },
    }),
    methods: {
      tableFetch() {
        this.paginateTableData(HTTP, `/${type}/rules`)
          .then(async ({ data }) => {
            if (data.length) {
              this.loading = true;
              if (type === 'android' || type === 'ios') {
                this.extendedItems = await fetchAppsSearch(data);
              } else {
                this.extendedItems = await fetchTreeSearch(data);
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      openForm(item) {
        this.form.edit = item;
        this.form.show = true;
      },
    },
  };
}
