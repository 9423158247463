<template>
  <div>
    <div>
      <v-alert ref="version-alert" type="error">
        Monthly Snap sets a date for which MediaMetrie's monthly or weekly feed will run. This will
        produce an additional file outside of the regulary scheduled feeds for mediametrie. Please
        contact dotdl@nielsen.com to receive permission to submit.
      </v-alert>
    </div>
    <div class="d-flex fill-height w-100 align-center justify-center">
      <v-date-picker
        v-model="date"
        :max="new Date().toISOString()"
        scrollable
        year-icon="mdi-calendar-export"
      >
        <v-container class="pa-0">
          <v-select v-model="kind" :items="kinds" label="Kind" class="w-100 pa-1" />
          <!-- add warning here -->
          <div class="d-flex w-100 justify-space-around pb-1">
            <v-btn text outlined @click="$router.back()">
              Cancel
            </v-btn>
            <v-btn :disabled="!date" text outlined @click="date = ''">
              Clear
            </v-btn>
            <v-btn :disabled="!$auth.hasPermissions('owl') || !date" text outlined @click="save">
              Submit
            </v-btn>
          </div>
        </v-container>
      </v-date-picker>
    </div>
  </div>
</template>

<script>
import HTTP from '@/api/owl';

export default {
  data: () => ({
    dialog: false,
    date: '',
    kind: 'nol',
    kinds: ['nol', 'emm'],
  }),
  methods: {
    save() {
      // This endpoint will run the MediaMetrie monthly or weekly feeds (s3 bucket is owl feed production audits/v1/mv...) at the date given
      // If date given is in the past the feedjob will preform it immediately
      HTTP.put(`/admin/snap/${this.kind}`, { date: this.date })
        .then(() => {
          this.$flash('success', 'Successful');
        })
        .catch(err => {
          this.$flash('error', err.toString());
        });
    },
  },
};
</script>
