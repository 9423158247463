<template>
  <span>
    <kbd>{{ before }}</kbd> &rarr; <kbd>{{ after }}</kbd>
  </span>
</template>

<script>
export default {
  props: {
    before: { type: String, default: '' },
    after: { type: String, default: '' },
  },
};
</script>
