<template>
  <v-row class="my-0">
    <nv-show cols="12" sm="3" header="Name">{{ proposed.name }}</nv-show>
    <nv-show cols="12" sm="4" header="Ad Supported">{{ proposed.ad_supported }}</nv-show>
    <nv-show :header="treeEntityType | humanize" cols="12" sm="5" no-placeholder>
      <tree-item :tree-id="proposed[treeEntityType]" />
    </nv-show>
    <nv-show cols="12" sm="12" header="Category">
      <category-show :subcategory-id="proposed.sub_category_id" />
    </nv-show>
  </v-row>
</template>

<script>
import CategoryShow from '@/components/mv/proposals/CategoryShow';
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  components: { TreeItem, CategoryShow },
  props: {
    proposed: {
      type: Object,
      required: true,
    },
  },
  computed: {
    treeEntityType() {
      return this.proposed.brand_id ? 'brand_id' : 'parent_id';
    },
  },
};
</script>
