<template>
  <v-data-table
    :headers="headers"
    :items="userAgents"
    :loading="loading"
    :options.sync="table"
    :server-items-length="totalItems"
    :footer-props="footerProps"
    :items-per-page.sync="table.itemsPerPage"
    hide-default-header
    no-data-text="No matching user agents found"
  >
    <template #item.country="{ item }">
      {{ item.country | uppercase }}
    </template>
  </v-data-table>
</template>

<script>
import HTTP from '@/api/ant';

const headers = [
  { text: 'User Agent', value: 'user_agent' },
  { text: 'Domain', value: 'domain' },
  { text: 'Country', value: 'country' },
];

export default {
  props: {
    pattern: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    userAgents: [],
    loading: false,
    table: {
      page: 1,
      itemsPerPage: 50,
    },
    totalItems: 0,
    headers,
  }),
  computed: {
    footerProps() {
      return {
        'items-per-page-options': [10, 20, 50],
        'disable-pagination': this.loading,
      };
    },
  },
  watch: {
    table: {
      deep: true,
      handler() {
        this.testPattern();
      },
    },
  },
  methods: {
    testPattern() {
      this.userAgents = [];
      if (this.pattern) {
        this.loading = true;
        HTTP.get('/ios/user_agents/match', {
          params: {
            pattern: this.pattern,
            domain: this.domain,
            page: this.table.page,
            per_page: this.table.itemsPerPage,
          },
        })
          .then(res => {
            this.userAgents = res.data;
            this.table.page = parseInt(res.headers['x-page'], 10) || this.table.page;
            this.table.itemsPerPage =
              parseInt(res.headers['x-per-page'], 10) || this.table.itemsPerPage;
            this.totalItems = parseInt(res.headers['x-total'], 10) || res.data.length;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
