<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      :errors="formErrors"
      :loading="loading"
      :title="title"
      data-cy="transfer-rules-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col v-if="value" cols="12">
          <app-dict-search v-if="isTargetApp" v-model="item.target_id" :rules="rules.targetId" />
          <tree-search
            v-else
            v-model="item.target_id"
            :rules="rules.targetId"
            mode="classification"
            label="Select Channel"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import TreeSearch from '@/components/mv/TreeSearch';
import AppDictSearch from '@/components/mv/AppDictSearch';
import Rules from 'bsm-validation-rules';

const rules = {
  targetId: [Rules.presence()],
};
const kinds = ['domain', 'ottm', 'video', 'android', 'ios'];

export default {
  components: { AppDictSearch, TreeSearch },
  mixins: [FormMixin],
  props: {
    kind: {
      type: String,
      required: true,
      validator: v => kinds.indexOf(v) !== -1,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    childId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    rules,
  }),
  computed: {
    title() {
      const res =
        this.kind === 'ottm'
          ? 'Connected Devices'
          : this.kind === 'ios'
          ? 'IOS'
          : this.$options.filters.humanize(this.kind);
      return `Transfer ${res} Patterns`;
    },
    isTargetApp() {
      return this.kind === 'android' || this.kind === 'ios';
    },
  },
  methods: {
    formInit() {
      return {
        transfer_resource_ids: this.selectedIds,
        target_id: null,
        source_id: this.childId,
      };
    },
    formSave() {
      return this.submitForm(HTTP, `/${this.kind}/rules/transfer`, {
        success() {
          this.$flash('success', `Transfer Proposal successfully created`);
        },
      });
    },
  },
};
</script>
