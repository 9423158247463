<template>
  <v-text-field
    ref="updated_by"
    :value="value"
    :rules="rules.email"
    label="Updated by"
    clearable
    append-icon="mdi-send-circle-outline"
    validate-on-blur
    @input="updatedBy = $event"
    @keydown.enter="onChange()"
    @click:append="onChange()"
  />
</template>

<script>
import Rules from 'bsm-validation-rules';

const rules = {
  email: [Rules.format(/.+@.+/, 'invalid email format')],
};

export default {
  name: 'UpdatedBySelector',
  props: {
    value: { type: String, default: null },
  },
  data: () => ({
    updatedBy: null,
    rules,
  }),
  methods: {
    onChange() {
      this.$refs['updated_by'].blur();
      if (this.updatedBy && !this.$refs['updated_by'].validate()) {
        return;
      }
      this.updatedBy = this.updatedBy?.trim();
      this.$emit('input', this.updatedBy);
    },
  },
};
</script>
