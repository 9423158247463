<template>
  <v-row align="center" justify="center" data-cy="proposals-filter">
    <v-col cols="12" sm="3">
      <v-select
        v-model="filters.item_kind"
        :items="itemKinds"
        label="Item Kind"
        clearable
        data-cy="item-kind"
      />
    </v-col>
    <v-col cols="12" sm="3">
      <v-text-field
        ref="created_by"
        v-model="emails.created_by"
        :rules="rules.email"
        label="Created By"
        append-icon="mdi-send-circle-outline"
        clearable
        validate-on-blur
        data-cy="created-by"
        @keydown.enter="onChange('created_by')"
        @click:append="onChange('created_by')"
      />
    </v-col>
    <v-col cols="12" sm="3">
      <v-text-field
        ref="reviewed_by"
        v-model="emails.reviewed_by"
        :rules="rules.email"
        label="Reviewed By"
        append-icon="mdi-send-circle-outline"
        clearable
        validate-on-blur
        data-cy="reviewed-by"
        @keydown.enter="onChange('reviewed_by')"
        @click:append="onChange('reviewed_by')"
      />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import Rules from 'bsm-validation-rules';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/owl';

const rules = {
  email: [Rules.format(/.+@.+/, 'invalid email format')],
};

export default {
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      item_kind: null,
      created_by: null,
      reviewed_by: null,
    },
    emails: {
      created_by: null,
      reviewed_by: null,
    },
    itemKinds: [],
    rules,
  }),
  watch: {
    'emails.created_by': function(val) {
      if (!val) {
        this.filters.created_by = null;
      }
    },
    'emails.reviewed_by': function(val) {
      if (!val) {
        this.filters.reviewed_by = null;
      }
    },
    'filters.created_by': function(val) {
      if (val !== this.emails.created_by) {
        this.emails.created_by = val;
      }
    },
    'filters.reviewed_by': function(val) {
      if (val !== this.emails.reviewed_by) {
        this.emails.reviewed_by = val;
      }
    },
  },
  created() {
    this.enumFetch(HTTP, {
      itemKinds: '/proposals/enum/item_kinds',
    });
  },
  methods: {
    onChange(key) {
      this.$refs[key].blur();
      if (!this.emails[key] || !this.$refs[key].validate()) {
        return;
      }
      this.emails[key] = this.emails[key].trim();
      this.filters[key] = this.emails[key];
    },
    enumFetch,
  },
};
</script>
