<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="3">
      <country-selector v-model="filters.country" clearable />
    </v-col>
    <v-col v-if="showDomain" cols="12" sm="3">
      <domain-filter-field v-model="filters.domain" />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import CountrySelector from '@/components/CountrySelector.vue';
import store from 'store';
import DomainFilterField from '@/components/cls/ios/DomainFilterField.vue';

const fns = store.namespace('filters');

export default {
  components: { DomainFilterField, CountrySelector },
  mixins: [FiltersMixin],
  props: {
    showDomain: { type: Boolean, default: false },
  },
  data: () => ({
    filters: {
      country: fns.get('country'),
      domain: null,
    },
  }),
  watch: {
    'filters.country': function(cur) {
      fns.set('country', cur);
    },
  },
};
</script>
