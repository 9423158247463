<template>
  <v-autocomplete
    ref="country"
    :value="value"
    :items="availableCountries"
    :rules="rules"
    label="Country"
    item-value="code"
    item-text="name"
    :clearable="clearable"
    :hide-details="!rules.length"
    data-cy="countries"
    @change="$emit('input', $event)"
  />
</template>

<script>
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/owl';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    restricted: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    except: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      default: '/countries?per_page=300',
    },
  },
  data: () => ({
    countries: [],
  }),
  computed: {
    availableCountries() {
      if (this.restricted) return this.countries.filter(el => this.value === el.code);
      return this.countries.filter(el => !this.except.includes(el.code));
    },
  },
  created() {
    this.enumFetch(HTTP, {
      countries: this.path,
    });
  },
  methods: {
    enumFetch,
  },
};
</script>
