<template>
  <v-col cols="4" sm="4" class="d-flex">
    <v-btn
      icon
      small
      class="v-data-table__expand-icon"
      :class="{ 'v-data-table__expand-icon--active': expand }"
      @click="expand = !expand"
    >
      <v-icon>mdi-chevron-down</v-icon>
    </v-btn>
    <v-simple-table dense>
      <thead>
        <tr class="elevation-0 v-data-table__expanded__content">
          <th>Wildcard</th>
          <th><v-icon small>mdi-trending-neutral</v-icon></th>
          <th>Replaces</th>
        </tr>
      </thead>
      <tbody v-show="expand">
        <tr
          v-for="w in wildcards"
          :key="w.wildcard"
          class="elevation-0 v-data-table__expanded__content"
        >
          <td>{{ w.wildcard }}</td>
          <td><v-icon small>mdi-trending-neutral</v-icon></td>
          <td>{{ w.replace }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>

<script>
const wildcards = [
  { wildcard: '$A$', replace: '[A-Za-z]*' },
  { wildcard: '$D$', replace: '[0-9.-_]*' },
  { wildcard: '$H$', replace: '[0-9A-Za-z._-]*' },
  { wildcard: '$N$', replace: 'Single Digit' },
];

export default {
  data: () => ({
    expand: false,
    wildcards,
  }),
};
</script>
