<template>
  <v-autocomplete
    ref="country"
    :value="value"
    :items="localCountries"
    :rules="rules"
    v-bind="$attrs"
    label="Country"
    item-text="name"
    item-value="code"
    data-cy="country"
    @change="$emit('input', $event)"
  />
</template>

<script>
import HTTP from '@/api/owl';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    localCountries: [],
  }),
  created() {
    this.enumFetch(HTTP, {
      localCountries: '/countries/localization',
    });
  },
  methods: {
    enumFetch,
  },
};
</script>
