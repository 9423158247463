<template>
  <div>
    <div class="text-no-wrap">{{ item.updated_at | time }}</div>
    <div class="text-caption">{{ item.updated_by }}</div>
  </div>
</template>

<script>
export default {
  name: 'UpdatedDetails',
  props: {
    item: { type: Object, required: true },
  },
};
</script>
