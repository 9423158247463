<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="3">
      <rules-status-selector v-model="filters.status" product="domain" filter />
    </v-col>
    <v-col cols="12" sm="3">
      <v-select ref="state" v-model="filters.state" :items="states" label="State" clearable />
    </v-col>
    <v-col cols="12" sm="3">
      <updated-by-selector v-model="filters.updated_by" />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import RulesStatusSelector from '@/components/cls/RulesStatusSelector.vue';
import UpdatedBySelector from '@/components/cls/UpdatedBySelector.vue';

export default {
  components: { UpdatedBySelector, RulesStatusSelector },
  mixins: [FiltersMixin],
  props: {
    states: { type: Array, required: true },
  },
  data: () => ({
    filters: {
      status: 'active',
      state: null,
      updated_by: null,
    },
  }),
};
</script>
