<template>
  <span v-if="subcategory">{{ subcategory.category.name }} / {{ subcategory.name }}</span>
  <kbd v-else-if="subcategoryId">{{ subcategoryId }}</kbd>
</template>

<script>
import HTTP from '@/api/owl';

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    subcategoryId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    localSubcategory: null,
  }),
  computed: {
    subcategory() {
      return this.item || this.localSubcategory;
    },
  },
  created() {
    if (this.subcategoryId) {
      this.fetchSubcategory();
    }
  },
  methods: {
    fetchSubcategory() {
      HTTP.get(`/sub_categories/${this.subcategoryId}`).then(({ data }) => {
        this.localSubcategory = data;
      });
    },
  },
};
</script>
