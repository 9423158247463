<template>
  <v-dialog :value="value" persistent max-width="900px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :save-disabled="!canDo('review') || !$auth.hasPermissions('ant.review')"
      :loading="loading"
      save-text="Submit"
      title="Review Proposal"
      data-cy="cls-review-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row class="my-0">
        <nv-show cols="12" sm="3" header="Proposal">
          {{ formatTitle(item) | humanize }}
        </nv-show>
        <nv-show cols="12" sm="4" header="Name">
          {{ item.name }}
        </nv-show>
        <nv-show cols="12" sm="5" header="Created By">
          {{ item.created_by }}
        </nv-show>
      </v-row>
      <v-row class="my-0">
        <nv-show
          v-if="showLink"
          :header="isApp ? 'Application' : 'Level'"
          cols="12"
          sm="3"
          no-placeholder
        >
          <app-dict-search-list-content v-if="isApp" :app-id="item.item.app_dictionary_id" link />
          <tree-item v-else :tree-id="item.item.tree_id" />
        </nv-show>
        <template v-if="item.reviewed_by">
          <nv-show :offset-sm="showLink ? 0 : 3" cols="12" sm="4" header="Status">
            {{ item.status | humanize }}
          </nv-show>
          <nv-show cols="12" sm="5" header="Reviewed By">
            {{ item.reviewed_by }}
          </nv-show>
          <nv-show v-if="item.review_comment" cols="12" sm="12" header="Comment">
            {{ item.review_comment }}
          </nv-show>
        </template>
      </v-row>
      <v-divider />
      <template v-if="item.action === 'update'">
        <app-update-table
          v-if="item.namespace.includes('android') || item.namespace.includes('ios')"
          :proposal="item"
        />
        <update-simple-table v-else :proposal="item" />
      </template>
      <transfer-patterns v-if="item.action === 'transfer'" :proposal="item" />
      <component
        :is="createReview"
        v-else-if="item.action === 'create'"
        :proposed="item.proposed_attributes"
      ></component>
      <v-divider />
      <v-row
        v-if="canDo('review') && $auth.hasPermissions('ant.review')"
        class="pt-6 my-0"
        align="center"
        no-gutters
      >
        <v-col cols="12">
          <span class="text-subtitle-1">Review:</span>
        </v-col>
        <v-col cols="auto" class="pr-6">
          <v-btn-toggle v-model="item.status" mandatory>
            <v-btn v-for="item in options" :key="item.value" :value="item.value" text small>
              {{ item.text }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col>
          <v-text-field
            v-model="item.review_comment"
            :rules="item.status === 'rejected' ? rules.comment : []"
            validate-on-blur
            label="Comment"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import AppUpdateTable from '@/components/cls/android/AppUpdateTable';
import UpdateSimpleTable from '@/components/UpdateSimpleTable';
import proposalsMixin from '@/helpers/proposals';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/ant';
import TransferPatterns from '@/components/cls/TransferPatterns';
import TreeItem from '@/components/cls/TreeItem.vue';
import AppDictSearchListContent from '@/components/mv/AppDictSearchListContent.vue';

export default {
  components: {
    AppDictSearchListContent,
    TreeItem,
    TransferPatterns,
    UpdateSimpleTable,
    AppUpdateTable,
  },
  mixins: [FormMixin, proposalsMixin],
  props: {
    value: { type: Boolean, default: false },
    namespace: { type: String, default: null },
    id: { type: Number, default: null },
  },
  computed: {
    createReview() {
      const namespace = this.namespace.replace('ottm', 'connected-devices');
      return () => import(`@/components/cls/${namespace}/CreateReview.vue`);
    },
    showLink() {
      return this.item?.action === 'update' && this.item.item != null;
    },
    isApp() {
      return this.item?.namespace?.includes('android') || this.item?.namespace?.includes('ios');
    },
  },
  methods: {
    async formInit() {
      if (this.id) {
        try {
          const { data } = await HTTP.get(`/${this.namespace}/${this.id}`);
          if (this.namespace === 'video/proposals/rule') {
            data.proposed_attributes['media_url'] = data.discovery?.media_url;
          }
          return data;
        } catch (err) {
          return {};
        }
      } else {
        return {};
      }
    },
    formSave() {
      return HTTP.put(`/${this.namespace}/${this.id}/review`, this.item, { niSkipFlash: true })
        .then(({ data }) => {
          this.$flash('success', `Proposal ${data.name} has been successfully ${data.status}`);
        })
        .catch(err => {
          this.formErrorHandler(err);
        });
    },
  },
};
</script>
