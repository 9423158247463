<template>
  <nv-app
    :navigation="navigation"
    :user="{ can: $auth.hasPermissions }"
    title="Global Digital Reference System"
    icon="/images/owl.svg"
  >
    <template #confirm>
      <nv-confirm ref="confirm" />
    </template>
  </nv-app>
</template>

<script>
const navigation = [
  { heading: 'Market View' },
  { icon: 'mdi-sitemap', text: 'Hierarchy', route: '/mv/hierarchy' },
  { icon: 'mdi-apps', text: 'Applications', route: '/mv/applications' },
  { icon: 'mdi-eye-check', text: 'Reviews', route: '/mv/reviews' },
  { heading: 'Classification' },
  {
    text: 'Domain',
    icon: 'mdi-web',
    children: [
      { icon: 'mdi-format-line-weight', text: 'Discovery', route: '/cls/domain/discovery' },
      { icon: 'mdi-link', text: 'Rules', route: '/cls/domain/rules' },
      { icon: 'mdi-eye-check', text: 'Reviews', route: '/cls/domain/reviews' },
    ],
  },
  {
    text: 'Video',
    icon: 'mdi-video-outline',
    children: [
      { icon: 'mdi-format-line-weight', text: 'Discovery', route: '/cls/video/discovery' },
      { icon: 'mdi-link', text: 'Rules', route: '/cls/video/rules' },
      { icon: 'mdi-eye-check', text: 'Reviews', route: '/cls/video/reviews' },
    ],
  },
  {
    text: 'Connected Devices',
    icon: 'mdi-television-classic',
    children: [
      {
        icon: 'mdi-format-line-weight',
        text: 'Discovery',
        route: '/cls/connected-devices/classify',
      },
      { icon: 'mdi-link', text: 'Rules', route: '/cls/connected-devices/rules' },
      { icon: 'mdi-eye-check', text: 'Reviews', route: '/cls/connected-devices/reviews' },
    ],
  },
  {
    text: 'Android',
    icon: 'mdi-android',
    children: [
      { icon: 'mdi-format-line-weight', text: 'Discovery', route: '/cls/android/discovery' },
      { icon: 'mdi-link', text: 'Rules', route: '/cls/android/rules' },
      { icon: 'mdi-eye-check', text: 'Reviews', route: '/cls/android/reviews' },
    ],
  },
  {
    text: 'IOS',
    icon: 'mdi-apple',
    children: [
      {
        icon: 'mdi-format-line-weight',
        text: 'Discovery (Bundle)',
        route: '/cls/ios/bundle/discovery',
      },
      { icon: 'mdi-format-line-weight', text: 'Discovery (UA)', route: '/cls/ios/ua/discovery' },
      { icon: 'mdi-link', text: 'Rules', route: '/cls/ios/rules' },
      { icon: 'mdi-eye-check', text: 'Reviews', route: '/cls/ios/reviews' },
      { text: 'User Agents', route: '/cls/ios/user_agents' },
    ],
  },
  { heading: 'Admin', required: ['owl'] },
  {
    icon: 'mdi-calendar-edit',
    text: 'Monthly Snap',
    route: '/admin/date',
    required: ['owl'],
  },
];

export default {
  name: 'App',
  data: () => ({
    navigation,
  }),
  mounted() {
    console.log('redeploy test');
    this.$root.$confirm = this.$refs.confirm.open;
  },
};
</script>
