<template>
  <div v-placeholder class="pt-2">
    <v-chip
      v-for="item in items"
      :key="item.id"
      :outlined="item.status !== 'active'"
      small
      class="ma-1"
      @click="openEdit(item)"
    >
      {{ item.cid }}&nbsp;/&nbsp;{{ item.code }}
    </v-chip>
    <v-chip
      v-for="item in proposedItems"
      :key="item.id"
      small
      class="ma-1"
      outlined
      @click="openReview(item)"
    >
      {{ item.proposed_attributes.cid }}&nbsp;/&nbsp;PENDING
    </v-chip>
    <v-btn
      v-if="!noAdd && $auth.hasPermissions('owl.marketview')"
      ref="add-c6-btn"
      color="secondary"
      rounded
      small
      class="ml-1"
      @click="addVcid"
    >
      <v-icon>mdi-plus</v-icon>
      <span class="ml-1">Add</span>
    </v-btn>
    <vcid-edit-form
      v-model="form.show"
      :editing="form.edit"
      :cids="cids"
      :channel-id="value"
      @saved="fetchData"
    />
    <review-form :id="reviewForm.id" v-model="reviewForm.show" :namespace="reviewForm.namespace" />
  </div>
</template>

<script>
import HTTP from '@/api/owl';
import VcidEditForm from '@/components/mv/VcidEditForm';
import ReviewForm from '@/components/mv/proposals/ReviewForm';

export default {
  components: { ReviewForm, VcidEditForm },
  props: {
    value: {
      type: Number,
      required: true,
    },
    noAdd: {
      type: Boolean,
      default: false,
    },
    cids: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    items: [],
    proposedItems: [],
    form: {
      show: false,
      edit: null,
    },
    reviewForm: {
      show: false,
      id: null,
      namespace: null,
    },
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/children/${this.value}/c6s`).then(({ data }) => {
        this.items = data;
      });
      if (!this.noAdd) {
        // it should only be possible to have a proposal in the case that you are allowed to add a
        // c6
        HTTP.get(`/channels/${this.value}/proposed/c6s`, {
          params: { filter: { status: 'pending' } },
        }).then(({ data }) => {
          this.proposedItems = data;
        });
      }
    },
    openEdit(item) {
      if (!this.$auth.hasPermissions('owl.marketview')) {
        return;
      }
      this.form.edit = { ...item, mv_child_id: this.value };
      this.form.show = true;
    },
    addVcid() {
      this.form.edit = null;
      this.form.show = true;
    },
    openReview(proposed) {
      this.reviewForm.namespace = proposed.namespace;
      this.reviewForm.id = proposed.id;
      this.reviewForm.show = true;
    },
  },
};
</script>
