<template>
  <v-row class="my-0">
    <v-col cols="4">
      <v-list dense class="py-0">
        <v-list-group
          v-if="parent != null"
          :value="!!parent.brands && parent.brands.length > 0"
          no-action
          @click.stop="setSelected(parent)"
        >
          <template #activator>
            <v-list-item-title @click.stop="setSelected(parent)">
              {{ parent.name }}
            </v-list-item-title>
          </template>

          <v-list-item v-if="!parent.brands || parent.brands.length === 0"></v-list-item>

          <v-list-group
            v-for="brand in parent.brands"
            :key="brand.id"
            no-action
            sub-group
            value="true"
          >
            <template #prependIcon>
              <v-icon v-if="brand.channels.length > 0">mdi-menu-right</v-icon>
              <span v-else />
            </template>
            <template #activator>
              <v-list-item-content @click.stop="setSelected(brand, 'Brand')">
                <v-list-item-title>{{ brand.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="channel in brand.channels"
              :key="channel.id"
              @click="setSelected(channel, 'Channel')"
            >
              <v-list-item-title>{{ channel.name }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-col>

    <v-divider v-if="selected.id" vertical />
    <v-col class="tree-details">
      <v-row v-if="selected.id" no-gutters>
        <nv-show cols="12" sm="6" header="Type">
          {{ selected.type }}
        </nv-show>
        <nv-show cols="12" sm="6" header="Name">
          {{ selected.name }}
        </nv-show>
        <nv-show cols="12" sm="6" header="Status">
          {{ selected.status | humanize }}
        </nv-show>
        <nv-show v-if="selected.type !== 'Parent'" cols="12" sm="6" header="Ad Supported">
          {{ selected.ad_supported | humanize }}
        </nv-show>
        <nv-show v-if="selected.sub_category" cols="12" sm="12" header="Category">
          <category-show :item="selected.sub_category" />
        </nv-show>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CategoryShow from '@/components/mv/proposals/CategoryShow';
export default {
  components: { CategoryShow },
  props: {
    parent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selected: { ...this.parent, type: 'Parent' },
    };
  },
  methods: {
    setSelected(item, type = 'Parent') {
      this.selected = {
        ...item,
        type,
      };
    },
  },
};
</script>

<style lang="sass">
.v-dialog .theme--dark .v-list.v-list--dense
  background-color: transparent !important

  .v-list-item--active
    color: inherit !important

    &:focus::before
      opacity: 0

  .v-list-item
    min-height: 30px

    .v-list-item__icon
      margin-top: 4px
      margin-bottom: 4px

.tree-details
  min-height: 202px
</style>
