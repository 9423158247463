<template>
  <nv-tab-page>
    <template #toolbar>
      <v-menu v-if="appId" offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="$auth.hasPermissions('ant.discovery')"
            :disabled="!selected.length && !tableFilters.domain && !search"
            color="accent"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="mr-1">mdi-transfer</v-icon>
            <span>Transfer</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :disabled="!selected.length" @click="openTransferForm()">
            <v-list-item-title> Selected ({{ selected.length }}) </v-list-item-title>
          </v-list-item>
          <v-list-item :disabled="!tableFilters.domain && !search" @click="openTransferScopeForm()">
            <v-list-item-title>
              Filtered
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer />
      <nv-table-search v-model="search" sync />
    </template>

    <template v-if="appId" #filters>
      <nv-table-filters
        ref="user-agent-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <ua-filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :show-select="!!appId"
      must-sort
    >
      <template #item="{ item, isSelected, select}">
        <tr>
          <td v-if="appId">
            <v-simple-checkbox v-ripple :value="isSelected" @input="select($event)" />
          </td>
          <td :title="item.user_agent">{{ cleanseUserAgent(item.user_agent) | truncate(120) }}</td>
          <td :title="item.domain">{{ item.domain }}</td>
          <td :title="item.app_name">{{ item.app_name | truncate(60) }}</td>
          <td>{{ item.country | uppercase }}</td>
          <td class="text-no-wrap">{{ item.updated_at | date }}</td>
        </tr>
      </template>
    </v-data-table>
    <transfer-user-agents-form
      v-if="appId"
      v-model="transferForm.show"
      :selected-ids="selectedIds"
      :child-id="appId"
      @saved="saved"
    />
    <transfer-user-agent-scope-form
      v-if="tableFilters.domain || search"
      v-model="transferScopeForm.show"
      :child-id="appId"
      :domain="tableFilters.domain"
      :user-agent="search"
      @saved="saved"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/ant';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import UaFilterSheet from '@/components/cls/ios/UaFilterSheet';
import TransferUserAgentsForm from '@/components/mv/applications/TransferUserAgentsForm';
import TransferUserAgentScopeForm from '@/components/mv/applications/TransferUserAgentScopeForm';
import { cleanseUserAgent } from '../../../helpers/cleanseData';

const headers = [
  { text: 'User Agent', sortable: false, value: 'user_agent' },
  { text: 'Domain', sortable: false, value: 'domain' },
  { text: 'App Name', sortable: false, value: 'app_name' },
  { text: 'Country', sortable: false, value: 'country' },
  { text: 'Updated At', sortable: true, value: 'updated_at' },
];

export default {
  components: { UaFilterSheet, TransferUserAgentsForm, TransferUserAgentScopeForm },
  mixins: [TableMixin],
  props: {
    appId: { type: Number, default: null },
    ruleId: { type: Number, default: null },
  },
  data() {
    return {
      table: {
        sortBy: ['updated_at'],
        sortDesc: [true],
      },
      headers,
      settings: {
        app_dictionary_id: this.appId,
        rule_id: this.ruleId,
      },
      tableParam: 'ua.dt',
      transferForm: {
        show: false,
        selectedIds: [],
      },
      transferScopeForm: {
        show: false,
      },
      selected: [],
    };
  },
  computed: {
    selectedIds() {
      return this.selected.map(el => el.id);
    },
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/ios/user_agents');
    },
    openTransferForm() {
      this.transferForm.show = true;
    },
    openTransferScopeForm() {
      this.transferScopeForm.show = true;
    },
    saved() {
      this.selected = [];
      this.tableFetch();
    },
    cleanseUserAgent(userAgent) {
      return cleanseUserAgent(userAgent);
    },
  },
};
</script>
