<template>
  <nv-tab-page>
    <template #toolbar>
      <v-row>
        <nv-btn v-if="$auth.hasPermissions('owl.marketview')" type="add" @click="openForm()" />
      </v-row>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item.country_code="{ item }">
        {{ item.country_code | uppercase }}
      </template>

      <template #item.start_date="{ item }">
        {{ item.start_date | date }}
      </template>

      <template #item.status="{ item }">
        {{ item.status | humanize }}
      </template>

      <template #item.edit="{ item }">
        <v-btn v-if="$auth.hasPermissions('owl.marketview')" icon @click="openForm(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <translations-form
      v-model="form.show"
      :application-id="id"
      :editing="form.edit"
      @saved="tableFetch"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/owl';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import TranslationsForm from '@/components/mv/applications/TranslationsForm.vue';

const headers = [
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Country', sortable: false, value: 'country_code' },
  { text: 'Start Date', sortable: true, value: 'start_date' },
  { text: 'Status', sortable: false, value: 'status' },
  { sortable: false, value: 'edit' },
];

export default {
  components: { TranslationsForm },
  mixins: [TableMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    headers,
    tableParam: 'local.dt',
    form: {
      show: false,
      edit: null,
    },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/app/dictionary/${this.id}/translations`);
    },
    openForm(item = null) {
      this.form.edit = item;
      this.form.show = true;
    },
  },
};
</script>
