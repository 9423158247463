<template>
  <v-dialog v-model="dialog" :width="width" persistent>
    <template #activator="{ on }">
      <v-text-field
        v-if="!icon"
        :value="value ? $options.filters.date(value) : ''"
        :label="label"
        :rules="rules"
        :disabled="disabled"
        append-icon="mdi-calendar"
        readonly
        v-on="on"
      />
      <v-icon v-else :disabled="disabled" v-on="on">mdi-calendar</v-icon>
    </template>
    <v-date-picker v-model="date" :min="minDate" scrollable year-icon="mdi-calendar-export">
      <v-spacer />
      <v-btn text outlined @click="dialog = false">
        Cancel
      </v-btn>
      <v-btn v-if="clearable" :disabled="!date" text outlined @click="date = ''">
        Clear
      </v-btn>
      <v-btn text outlined @click="save">
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Date',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: null,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    date: null,
  }),
  computed: {
    width() {
      if (this.clearable) {
        return 305;
      }
      return 290;
    },
    minDate() {
      const today = new Date();
      if (!this.startDate) {
        return today.toISOString();
      }
      const startDate = new Date(this.startDate);
      return today > startDate ? today.toISOString() : startDate.toISOString();
    },
  },
  watch: {
    dialog(val) {
      this.date = val ? this.value : '';
    },
  },
  methods: {
    save() {
      this.$emit('input', this.date);
      this.dialog = false;
    },
  },
};
</script>
