<template>
  <nv-page header="Domain Discovery" data-cy="domain-discovery">
    <template #toolbar>
      <v-spacer />
      <nv-btn
        v-if="items.length && $auth.hasPermissions('ant.discovery')"
        type="lock"
        data-cy="lock-btn"
        @click="reserve()"
      />
      <nv-table-search v-model="search" sync class="ml-4" data-cy="search" />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        v-model="domainFilters"
        :no-clear="isDefaultFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" @update:source="sourceUpdate" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <a :href="'http://' + item.domain" target="_blank">{{ item.domain }}</a>
          </td>
          <td>{{ item.countries.join(', ') | uppercase }}</td>
          <td>
            <template v-if="table.sortBy[0] === 'rank_desktop'">
              {{ item.hits_desktop | number }}
            </template>
            <template v-else-if="table.sortBy[0] === 'rank_mobile'">
              {{ item.hits_mobile | number }}
            </template>
            <template v-else>{{ item.hits | number }}</template>
          </td>
          <td>{{ item.last_seen_on | date }}</td>
        </tr>
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import FilterSheet from '@/components/cls/domain/FilterSheet';
import DiscoveryList from '@/components/cls/DiscoveryList';

const headers = [
  { text: 'Domain', sortable: false, value: 'domain' },
  { text: 'Countries', sortable: false, value: 'countries' },
  { text: 'Audience', sortable: false, value: 'rank' },
  { text: 'Last Seen', sortable: false, value: 'last_seen_on' },
];

export default {
  components: { FilterSheet },
  extends: DiscoveryList('domain'),
  data: () => ({
    headers,
  }),
  computed: {
    domainFilters() {
      const s = {};
      if (this.table.sortBy[0] !== 'rank') {
        s.domainSource = this.table.sortBy[0];
      }
      return { ...this.formattedFilters, ...s };
    },
    isDefaultFilters() {
      return Object.values(this.domainFilters).filter(v => !!v).length === 1;
    },
  },
  methods: {
    sourceUpdate(source) {
      this.table.sortBy = source ? [source] : ['rank'];
    },
  },
};
</script>
