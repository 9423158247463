import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/ant';
import { extendProposalsItems } from '@/helpers/owlFind.js';

const headers = [
  { text: 'Type', sortable: false, value: 'item_kind' },
  { text: 'Name', sortable: false, value: 'name' },
  { text: 'Level', sortable: false, value: 'level' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Created By', sortable: false, value: 'created_by' },
  { text: 'Updated At', sortable: true, value: 'updated_at' },
];

export default function(type) {
  return {
    mixins: [TableMixin],
    data: () => ({
      headers,
      form: {
        show: false,
        id: null,
        namespace: null,
      },
      settings: {},
      savedTableFilters: {},
      selected: [],
      approveLoading: false,
      table: {
        sortBy: ['updated_at'],
        sortDesc: [true],
      },
      extendedItems: [],
    }),
    watch: {
      settings(cur, prev) {
        if (this.isAll) {
          this.tableFilters = { ...this.savedTableFilters };
        } else if (Object.keys(prev).length === 0) {
          this.savedTableFilters = { ...this.tableFilters };
          this.tableFilters = {};
        }
        this.tableFetch();
      },
    },
    computed: {
      showSelect() {
        return (
          this.settings.can === 'review' &&
          !!this.items.length &&
          this.$auth.hasPermissions('ant.review')
        );
      },
      selectedIds() {
        return this.selected.map(el => el.id);
      },
      isAll() {
        return Object.keys(this.settings).length === 0;
      },
    },
    methods: {
      tableFetch() {
        this.paginateTableData(HTTP, `/${type}/proposals`)
          .then(async ({ data }) => {
            if (data.length) {
              this.loading = true;
              this.extendedItems = await extendProposalsItems(data, type);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      openForm(item) {
        this.form.id = item.id;
        this.form.namespace = item.namespace;
        this.form.show = true;
      },
      setTableSettings(settings) {
        this.items = [];
        this.selected = [];
        this.settings = settings;
      },
      bulkApprove() {
        this.approveLoading = true;
        HTTP.put(`/${type}/proposals/bulk/approve`, { ids: this.selectedIds })
          .then(() => {
            this.$flash('success', `Proposals have been successfully approved`);
            this.selected = [];
            this.tableFetch();
          })
          .catch(err => {
            this.$flash('error', err.toString());
          })
          .finally(() => {
            this.approveLoading = false;
          });
      },
    },
  };
}
