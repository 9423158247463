import SEARCH from '@/api/owl-search.js';

export function fetchTreeSearch(items) {
  const ids = items
    .map(el => el.tree_id)
    .filter(el => el != null)
    .join(',');
  if (ids) {
    return SEARCH.get('/tree/find', {
      params: {
        id: ids,
      },
    })
      .then(({ data }) => {
        return items.map(item => {
          const channel = data.find(el => el.id === item.tree_id) || {};
          return {
            ...item,
            channel,
          };
        });
      })
      .catch(() => {
        return items;
      });
  } else {
    return Promise.resolve(items);
  }
}

export function fetchAppsSearch(items) {
  const ids = items
    .map(el => el.app_dictionary_id)
    .filter(el => el != null)
    .join(',');
  if (ids) {
    return SEARCH.get('/apps/find', {
      params: {
        id: ids,
      },
    })
      .then(({ data }) => {
        return items.map(item => {
          const application = data.find(el => el.id === item.app_dictionary_id);
          return {
            ...item,
            application,
          };
        });
      })
      .catch(() => {
        return items;
      });
  } else {
    return Promise.resolve(items);
  }
}

export function extendProposalsItems(data, type) {
  let items = [];
  if (type === 'android' || type === 'ios') {
    items = data.map(el => {
      const app_dictionary_id =
        el.action === 'create'
          ? el.proposed_attributes?.app_dictionary_id
          : el.item?.app_dictionary_id;
      return { ...el, app_dictionary_id };
    });
    return fetchAppsSearch(items);
  } else {
    items = data.map(el => {
      const tree_id = el.action === 'create' ? el.proposed_attributes?.tree_id : el.item?.tree_id;
      return { ...el, tree_id };
    });
    return fetchTreeSearch(items);
  }
}
