<template>
  <v-dialog :value="value" persistent max-width="900px" scrollable>
    <nv-form
      :loading="loading"
      title="Test Pattern"
      save-text="Test"
      @cancel="dialogCancel"
      @nv-submit="testPattern"
    >
      <v-row class="mb-4">
        <v-col v-if="product === 'ios'" cols="12">
          <v-btn-toggle v-model="iosType" mandatory>
            <v-btn text small value="bundle">Bundle</v-btn>
            <v-btn text small value="ua">User Agents</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="pattern"
            :rules="rules.pattern"
            label="Pattern"
            autofocus
            validate-on-blur
          />
        </v-col>
        <v-col v-if="product === 'ios'" cols="12">
          <v-text-field v-model="scope" :label="scopeLabel" />
        </v-col>
      </v-row>
      <template v-if="matchResponse">
        <div v-if="!matchResponse.rule_id">No rule found</div>
        <component :is="ruleDetails" v-else :rule="rule" />
      </template>
    </nv-form>
  </v-dialog>
</template>

<script>
import ANT_MATCH from '@/api/ant-match.js';
import ANT from '@/api/ant.js';
import Rules from 'bsm-validation-rules';

const products = ['domain', 'video', 'android', 'ios'];
const rules = {
  pattern: [Rules.presence()],
};

export default {
  name: 'TestRulesPatterns',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    product: {
      type: String,
      required: true,
      validator: v => products.indexOf(v) !== -1,
    },
  },
  data: () => ({
    pattern: '',
    scope: '',
    matchResponse: null,
    rule: {},
    loading: false,
    rules,
    iosType: 'bundle',
  }),
  computed: {
    scopeLabel() {
      return this.iosType === 'bundle' ? 'App Name' : 'Domain';
    },
  },
  methods: {
    testPattern(form) {
      this.pattern = this.pattern?.trim();
      this.scope = this.scope?.trim();
      if (!form.validate()) {
        return;
      }
      this.loading = true;
      const path = this.product === 'ios' ? `${this.product}/${this.iosType}` : this.product;
      const params = { pattern: this.pattern };
      if (this.scope) {
        params.scope = this.scope;
      }
      ANT_MATCH.get(`/match/${path}`, { params })
        .then(({ data }) => {
          this.matchResponse = data;
          if (data.rule_id) {
            ANT.get(`/${this.product}/rules/${data.rule_id}`)
              .then(resp => {
                this.rule = resp.data;
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dialogCancel(form) {
      this.$emit('input', false);
      this.pattern = '';
      this.scope = '';
      this.matchResponse = null;
      this.rule = {};
      form.resetValidation();
    },
    ruleDetails() {
      return import(`@/components/cls/${this.product}/RuleDetails.vue`);
    },
  },
};
</script>
