<template>
  <v-row class="my-0">
    <nv-show cols="12" sm="3" header="Country Code">
      {{ proposed.country_code | uppercase }}
    </nv-show>
    <nv-show cols="12" sm="4" header="Relabel">
      {{ proposed.replacement_name }}
    </nv-show>
    <nv-show cols="12" sm="5" header="Level" no-placeholder>
      <tree-item :tree-id="proposed.entity_id" />
    </nv-show>
  </v-row>
</template>

<script>
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  name: 'LocalizationRules',
  components: { TreeItem },
  props: {
    proposed: {
      type: Object,
      required: true,
    },
  },
};
</script>
