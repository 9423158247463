<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      :errors="formErrors"
      :loading="loading"
      title="Transfer User Agents"
      data-cy="transfer-user-agents-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col v-if="value" cols="12">
          <app-dict-search v-model="item.target_id" :rules="rules.targetId" />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import AppDictSearch from '@/components/mv/AppDictSearch';
import Rules from 'bsm-validation-rules';

const rules = {
  targetId: [Rules.presence()],
};

export default {
  components: { AppDictSearch },
  mixins: [FormMixin],
  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
    childId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    rules,
  }),
  methods: {
    formInit() {
      return {
        transfer_resource_ids: this.selectedIds,
        target_id: null,
        source_id: this.childId,
      };
    },
    formSave() {
      return this.submitForm(HTTP, `/ios/user_agents/transfer`, {
        success() {
          this.$flash('success', `Transfer Proposal successfully created`);
        },
      });
    },
  },
};
</script>
