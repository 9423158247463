<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    persistent
    content-class="h-100 ml-auto mr-0 my-0 max-height-100"
    transition="dialog-right-transition"
  >
    <template #activator="{ on }">
      <v-text-field
        :value="channel.name"
        :rules="rules"
        :prepend-inner-icon="
          channel.id ? `mdi-alpha-${channel.type[0]}-box-outline` : 'mdi-magnify'
        "
        :disabled="disabled"
        label="Search Channel"
        readonly
        solo
        flat
        hide-details="auto"
        data-cy="channel-select"
        v-on="on"
        @keydown.enter="dialog = true"
        @keydown.space="dialog = true"
      />
    </template>
    <v-card height="100%" data-cy="hierarchy-discovery">
      <v-card-title>
        <nv-table-search v-model="search" :loading="loading" autofocus />
        <v-icon class="mr-2 ml-4">mdi-filter-variant</v-icon>
        <v-btn-toggle v-model="filters.type" multiple>
          <v-btn
            v-for="type in types"
            :key="type"
            data-cy="hierarchy-level"
            :value="type"
            text
            small
          >
            <v-icon>{{ `mdi-alpha-${type[0]}` }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-text>
        <v-row dense class="h-100">
          <v-col cols="6" class="scrollable">
            <v-list dense>
              <v-list-item v-for="(item, i) in searchItems" :key="i" @click="excerptData(item)">
                <search-list-avatar :type="item.type" />
                <search-list-content :item="item" />
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="6" class="scrollable">
            <v-treeview
              v-if="excerptItems.length"
              :items="excerptItems"
              :active.sync="active"
              activatable
              return-object
              open-all
              dense
              hoverable
            ></v-treeview>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="dialog = false">Close</v-btn>
        <v-btn
          :disabled="!active[0] || !active[0].classifiable"
          color="secondary"
          class="submit"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl-search';
import { debounce } from 'lodash';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import SearchListContent from '@/components/mv/SearchListContent';

function updateSearch(val) {
  if (val) {
    this.fetchData(val);
  } else {
    this.searchItems = [];
    this.excerptItems = [];
  }
}

export default {
  components: { SearchListContent, SearchListAvatar },
  props: {
    value: {
      type: Number,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    channel: {},
    search: '',
    loading: false,
    searchItems: [],
    excerptItems: [],
    active: [],
    dialog: false,
    types: ['parent', 'brand', 'channel'],
    filters: {
      type: [],
    },
  }),
  watch: {
    search: debounce(updateSearch, 500),
    'filters.type': {
      handler() {
        if (this.search) {
          this.fetchData(this.search);
        }
      },
    },
  },
  methods: {
    fetchData(search) {
      this.loading = true;
      this.active = [];
      const type = this.filters.type.join(',');
      const params = {
        q: search,
        type,
      };
      HTTP.get('/tree/search', { params })
        .then(({ data }) => {
          if (params.q === this.search) {
            this.searchItems = data;
          }
        })
        .finally(() => {
          this.excerptItems = [];
          this.loading = false;
        });
    },
    excerptData(item) {
      this.excerptItems = [];
      this.loading = true;
      HTTP.get('/tree/excerpt', {
        params: {
          id: item.id,
          limit: 1000,
        },
      })
        .then(({ data }) => {
          this.excerptItems = [data];
          this.active = [item];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.dialog = false;
      this.channel = this.active[0];
      this.$emit('input', this.channel.id);
    },
  },
};
</script>

<style lang="scss">
.scrollable,
.max-height-100 {
  max-height: 100% !important;
}
.scrollable > div {
  overflow: auto;
  max-height: 100%;
}
.dialog-right-transition {
  &-enter,
  &-leave-to {
    transform: translateX(100%);
  }
}
</style>
