<template>
  <v-col class="text-end" cols="auto" data-cy="block-filter-action">
    <v-icon disabled class="mr-2">mdi-filter-variant</v-icon>
    <v-btn-toggle v-model="localFilter" mandatory>
      <v-btn v-for="item in actions" :key="item.value" :value="item.value" text small>
        {{ item.text }}
      </v-btn>
    </v-btn-toggle>
  </v-col>
</template>

<script>
export default {
  data: () => ({
    settings: {},
    localFilter: 'all',
    actions: [
      { text: 'My Proposals', value: 'mine' },
      { text: 'Review', value: 'review' },
      { text: 'All', value: 'all' },
    ],
  }),
  watch: {
    localFilter(val) {
      switch (val) {
        case 'mine':
          this.settings = { created_by: this.$auth.user.email };
          break;
        case 'review':
          this.settings = { can: 'review' };
          break;
        default:
          this.settings = {};
      }
    },
    settings(val) {
      this.$emit('changed', val);
    },
  },
};
</script>
