<template>
  <nv-tab-page>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
    >
      <template #item="{ item }">
        <review-row :item="item" mv show-changes hide-name @open-form="openForm" />
      </template>
    </v-data-table>
    <review-form
      :id="form.id"
      v-model="form.show"
      :namespace="form.namespace"
      @saved="tableFetch"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/owl';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import ReviewRow from '@/components/ReviewRow';
import ReviewForm from '@/components/mv/proposals/ReviewForm';

const headers = [
  { text: 'Type', sortable: false, value: 'action' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Created By', sortable: false, value: 'created_by' },
  { text: 'Changes', sortable: false },
  { text: 'Updated At', sortable: true, value: 'updated_at' },
];

const itemTypes = ['app/dictionary', 'children', 'parents'];

export default {
  components: { ReviewForm, ReviewRow },
  mixins: [TableMixin],
  props: {
    id: { type: Number, required: true },
    type: {
      type: String,
      required: true,
      validator: v => itemTypes.indexOf(v) !== -1,
    },
  },
  data() {
    return {
      headers,
      tableParam: 'history.dt',
      form: {
        show: false,
        id: null,
        namespace: null,
      },
      table: {
        sortBy: ['updated_at'],
        sortDesc: [true],
      },
      settings: {
        status: 'approved',
      },
    };
  },
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/${this.type}/${this.id}/proposals`);
    },
    openForm(item) {
      this.form.id = item.id;
      this.form.namespace = item.namespace;
      this.form.show = true;
    },
  },
};
</script>
