<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="translations-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-text-field
            ref="name"
            v-model="item.name"
            :rules="rules.name"
            label="Name"
            counter="500"
            data-cy="name"
          />
        </v-col>
        <v-col cols="12">
          <local-country-selector
            v-model="item.country_code"
            :rules="rules.country_code"
            :disabled="item.id != null"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            ref="status"
            v-model="item.status"
            :items="statuses"
            :rules="rules.status"
            label="Status"
            data-cy="status"
          />
        </v-col>
        <v-col cols="12">
          <owl-date-picker
            ref="start-date"
            v-model="item.start_date"
            :rules="rules.start_date"
            label="Start Date"
            data-cy="start-date"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';
import OwlDatePicker from '@/components/OwlDatePicker';
import LocalCountrySelector from '@/components/mv/LocalCountrySelector';

const rules = {
  name: [Rules.presence(), Rules.length({ max: 500 })],
  country_code: [Rules.presence()],
  status: [Rules.presence()],
  start_date: [Rules.presence()],
};

const statuses = [
  { text: 'Active', value: 'active' },
  { text: 'Inactive', value: 'inactive' },
];

export default {
  components: { LocalCountrySelector, OwlDatePicker },
  mixins: [FormMixin],
  props: {
    applicationId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    localCountries: [],
    rules,
    statuses,
    formContext: 'Translation',
  }),
  methods: {
    formSave() {
      let path;
      if (this.editing != null) {
        path = '/app/translations';
      } else {
        path = `/app/dictionary/${this.applicationId}/translations`;
      }
      return this.submitForm(HTTP, path, {
        success: ({ data }) => {
          this.$flash('success', `${data.name} ${this.formContext} ${this.formSuccessMsg}`);
        },
      });
    },
  },
};
</script>
