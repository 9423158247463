<template>
  <nv-page header="Android Discovery" data-cy="android-discovery">
    <template #toolbar>
      <v-spacer />
      <nv-btn
        v-if="items.length && $auth.hasPermissions('ant.discovery')"
        type="lock"
        data-cy="lock-btn"
        @click="reserve()"
      />
      <nv-table-search v-model="search" sync class="ml-4" data-cy="search" />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="formattedFilters"
        :no-clear="isDefaultFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <tr>
          <td>
            <a
              :href="'https://play.google.com/store/apps/details?id=' + item.package_id"
              target="_blank"
              class="text-subtitle-1"
            >
              {{ item.package_id }}
            </a>
          </td>
          <td>{{ item.countries.join(', ') | uppercase }}</td>
          <td>{{ item.hits | number }}</td>
          <td>{{ item.last_seen_on | date }}</td>
        </tr>
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import FilterSheet from '@/components/cls/FilterSheet';
import DiscoveryList from '@/components/cls/DiscoveryList';

const headers = [
  { text: 'Package', sortable: false, value: 'package_id' },
  { text: 'Countries', sortable: false, value: 'countries' },
  { text: 'Audience', sortable: false, value: 'rank' },
  { text: 'Last Seen', sortable: false, value: 'last_seen_on' },
];

export default {
  components: { FilterSheet },
  extends: DiscoveryList('android'),
  data: () => ({
    headers,
  }),
};
</script>
