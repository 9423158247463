<template>
  <v-data-table
    :headers="headers"
    :items="discoveries"
    :loading="loading"
    :options.sync="table"
    :server-items-length="totalItems"
    :footer-props="footerProps"
    :items-per-page.sync="table.itemsPerPage"
    hide-default-header
    no-data-text="No matching discoveries found"
  >
    <template #item.countries="{ item }">
      <span>{{ item.countries.join(', ') | uppercase }}</span>
    </template>
  </v-data-table>
</template>

<script>
import HTTP from '@/api/ant';

const videoHeaders = [{ text: 'Media URL', value: 'media_url' }];
const iosHeaders = [
  { text: 'User Agent', value: 'user_agent' },
  { text: 'Domain', value: 'domain' },
  { text: 'Countries', value: 'countries' },
];
export default {
  props: {
    domain: {
      type: String,
      default: '',
    },
    pattern: {
      type: String,
      required: true,
    },
    product: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      discoveries: [],
      loading: false,
      table: {
        page: 1,
        itemsPerPage: 50,
      },
      totalItems: 0,
      headers: this.product === 'video' ? videoHeaders : iosHeaders,
    };
  },
  computed: {
    footerProps() {
      return {
        'items-per-page-options': [10, 20, 50],
        'disable-pagination': this.loading,
      };
    },
  },
  watch: {
    table: {
      deep: true,
      handler() {
        this.testPattern();
      },
    },
  },
  methods: {
    testPattern() {
      this.discoveries = [];
      if (this.pattern) {
        this.loading = true;
        const params = {
          pattern: this.pattern,
          page: this.table.page,
          per_page: this.table.itemsPerPage,
        };
        if (this.domain) {
          params.domain = this.domain;
        }
        HTTP.get(`/${this.product}/discoveries/match`, { params })
          .then(res => {
            this.discoveries = res.data;
            this.table.page = parseInt(res.headers['x-page'], 10) || this.table.page;
            this.table.itemsPerPage =
              parseInt(res.headers['x-per-page'], 10) || this.table.itemsPerPage;
            this.totalItems = parseInt(res.headers['x-total'], 10) || res.data.length;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
