<template>
  <nv-page header="Classify Domain" data-cy="domain-classify">
    <template #toolbar>
      <v-spacer />
      <v-chip v-if="items.length" label outlined>
        Lock expires at {{ items[0].lock_expires_at | time('short') }}
      </v-chip>
      <v-spacer />
      <v-btn
        v-if="$auth.hasPermissions('owl.marketview')"
        color="secondary"
        @click="createForm.show = true"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>
        <span>Create Channel</span>
      </v-btn>
      <nv-btn
        v-if="$auth.hasPermissions('ant.discovery')"
        type="unlock"
        data-cy="classify-unlock-btn"
        @click="unlock()"
      />
    </template>

    <template #filters>
      <nv-table-filters ref="applied-filters" v-model="tableFilters" no-clear />
    </template>

    <v-data-table
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :headers-length="5"
      hide-default-header
    >
      <template #header>
        <thead class="v-data-table-header">
          <tr>
            <th>Domain</th>
            <th colspan="3">
              <v-row>
                <v-col cols="5">Tree ID</v-col>
              </v-row>
            </th>
          </tr>
        </thead>
      </template>
      <template #item="{ item }">
        <classify-row :item="item" />
      </template>
    </v-data-table>
    <brand-channel-form v-model="createForm.show" channel cls />
  </nv-page>
</template>

<script>
import ClassifyList from '@/components/cls/ClassifyList';
import ClassifyRow from '@/components/cls/domain/ClassifyRow';
import BrandChannelForm from '@/components/mv/BrandChannelForm';

export default {
  components: { BrandChannelForm, ClassifyRow },
  extends: ClassifyList('domain'),
  data: () => ({
    table: {
      sortBy: ['rank'],
      sortDesc: [true],
    },
  }),
  created() {
    this.setTableSort();
  },
  methods: {
    setTableSort() {
      if (localStorage.getItem('domain_sort_by')) {
        this.table.sortBy = [localStorage.getItem('domain_sort_by')];
      }
      if (localStorage.getItem('domain_sort_desc')) {
        this.table.sortDesc = [localStorage.getItem('domain_sort_desc')];
      }
    },
  },
};
</script>
