<template>
  <nv-page header="Domains Rules" data-cy="domain-rules-list">
    <template #toolbar>
      <v-spacer />
      <v-btn class="mr-4" @click="testDialog.show = true">Test Pattern</v-btn>
      <nv-table-search v-model="search" sync />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        v-model="tableFilters"
        @clear="$refs.filters.clearFilters()"
      >
        <rules-filter-sheet ref="filters" :states="domainStates" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="extendedItems"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <router-link :to="{ name: 'owl.domain.rules.show', params: { ruleId: item.id } }">
              {{ item.pattern }}
            </router-link>
          </td>
          <td>
            <v-btn :href="'https://' + item.pattern" icon target="_blank">
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </td>
          <td>{{ item.tree_id }}</td>
          <td>
            <tree-item v-if="item.channel && item.channel.id" :item="item.channel" />
            <span v-else>Unavailable</span>
          </td>
          <td>{{ item.state | enumLabel(domainStates) }}</td>
          <td>{{ item.status | enumLabel(domainStatuses) }}</td>
          <td>
            <updated-details :item="item" />
          </td>
          <td class="text-end">
            <v-btn v-if="$auth.hasPermissions('ant.discovery')" icon @click="openForm(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <rules-edit-form
      v-model="form.show"
      :editing="form.edit"
      :states="domainStates"
      @saved="tableFetch"
    />
    <test-rules-patterns v-model="testDialog.show" product="domain" />
  </nv-page>
</template>

<script>
import RulesList from '@/components/cls/RulesList';
import RulesEditForm from '@/components/cls/domain/RulesEditForm';
import RulesFilterSheet from '@/components/cls/domain/RulesFilterSheet';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/ant';
import TreeItem from '@/components/cls/TreeItem.vue';
import TestRulesPatterns from '@/components/cls/TestRulesPatterns.vue';
import UpdatedDetails from '@/components/cls/UpdatedDetails.vue';

const headers = [
  { text: 'Rule', sortable: false, value: 'pattern' },
  { text: '', sortable: false, value: 'pattern' },
  { text: 'Tree ID', sortable: false, value: 'tree_id' },
  { text: 'Level', sortable: false, value: 'channel' },
  { text: 'State', sortable: false, value: 'state' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Updated', sortable: false, value: 'updated_by' },
  { sortable: false, value: 'edit', align: 'end' },
];

export default {
  components: { UpdatedDetails, TestRulesPatterns, RulesFilterSheet, RulesEditForm, TreeItem },
  extends: RulesList('domain'),
  data: () => ({
    headers,
    domainStatuses: [],
    domainStates: [],
    testDialog: { show: false },
  }),
  created() {
    this.enumFetch(HTTP, {
      domainStatuses: '/domain/rules/enum/statuses',
      domainStates: '/domain/rules/enum/states',
    });
  },
  methods: {
    enumFetch,
  },
};
</script>
