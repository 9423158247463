<template>
  <v-row dense align="center" justify="center">
    <v-col class="text-center" cols="12" sm="3" data-cy="block-filter-status">
      <status-selector v-model="filters.status" filter />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import StatusSelector from '@/components/mv/StatusSelector';

export default {
  components: { StatusSelector },
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
    },
    filtersParam: 'child.ft',
  }),
};
</script>
