<template>
  <v-text-field
    ref="domain"
    :value="value"
    label="Domain"
    append-icon="mdi-send-circle-outline"
    clearable
    data-cy="domain"
    hide-details
    @input="domain = $event"
    @keydown.enter="onDomainChange()"
    @click:append="onDomainChange()"
  />
</template>

<script>
export default {
  props: {
    value: { type: String, default: '' },
  },
  data: () => ({
    domain: '',
  }),
  methods: {
    onDomainChange() {
      this.$emit('input', this.domain);
    },
  },
};
</script>
