<template>
  <v-select
    :value="value"
    :items="statuses"
    :rules="filter ? [] : rules.status"
    :clearable="filter"
    :placeholder="filter ? 'All' : null"
    :data-cy="'cls-status' + (filter ? '-filter' : '')"
    label="Status"
    @change="$emit('input', $event)"
  />
</template>

<script>
import HTTP from '@/api/ant.js';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';

const rules = {
  status: [Rules.presence()],
};

const products = ['domain', 'ottm', 'video', 'android', 'ios'];

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    filter: {
      type: Boolean,
      default: false,
    },
    product: {
      type: String,
      required: true,
      validator: v => products.indexOf(v) !== -1,
    },
  },
  data: () => ({
    statuses: [],
    rules,
    enumFetch,
  }),
  created() {
    this.enumFetch(HTTP, {
      statuses: `/${this.product}/rules/enum/statuses`,
    });
  },
};
</script>
