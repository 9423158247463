<template>
  <nv-tab-page>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
    >
      <template #item="{ item }">
        <review-row :item="item" mv @open-form="openForm" />
      </template>
    </v-data-table>
    <cls-review-form
      v-if="form.namespace"
      :id="form.id"
      v-model="form.show"
      :namespace="form.namespace"
      @saved="tableFetch"
    />
  </nv-tab-page>
</template>

<script>
import ReviewRow from '@/components/ReviewRow.vue';
import ClsReviewForm from '@/components/cls/ReviewForm';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/ant.js';

const headers = [
  { text: 'Type', sortable: false, value: 'item_kind' },
  { text: 'Name', sortable: false, value: 'name' },
  { text: 'Status', sortable: false, value: 'status' },
  { text: 'Created By', sortable: false, value: 'created_by' },
  { text: 'Updated At', sortable: true, value: 'updated_at' },
];

export default {
  name: 'RulesHistoryTab',
  components: { ReviewRow, ClsReviewForm },
  mixins: [TableMixin],
  props: {
    product: { type: String, required: true },
    id: { type: Number, required: true },
  },
  data: () => ({
    headers,
    tableParam: 'history.dt',
    form: {
      show: false,
      id: null,
      namespace: null,
    },
    table: {
      sortBy: ['updated_at'],
      sortDesc: [true],
    },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/${this.product}/rules/${this.id}/proposals`);
    },
    openForm(item) {
      this.form.id = item.id;
      this.form.namespace = item.namespace;
      this.form.show = true;
    },
  },
};
</script>
