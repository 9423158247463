<template>
  <v-select
    :value="value"
    :items="statuses"
    :rules="filter ? [] : rules.status"
    :clearable="filter"
    :placeholder="filter ? 'All' : null"
    :hide-details="filter"
    :data-cy="'mv-status' + (filter ? '-filter' : '')"
    label="Status"
    @change="$emit('input', $event)"
  />
</template>

<script>
import HTTP from '@/api/owl';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';

const rules = {
  status: [Rules.presence()],
};

export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
      default: '',
    },
    filter: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    statuses: [],
    rules,
  }),
  created() {
    this.enumFetch(HTTP, {
      statuses: '/enum/statuses',
    });
  },
  methods: {
    enumFetch,
  },
};
</script>
