<template>
  <nv-page header="IOS Rule">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn v-if="$auth.hasPermissions('ant.discovery')" type="edit" @click="openForm(item)" />
    </template>
    <v-card class="pa-4" data-cy="ios-rule-details">
      <v-row>
        <nv-show cols="12" sm="6" header="Rule">{{ item.pattern }}</nv-show>
        <nv-show cols="12" sm="2" header="Domain">{{ item.domain }}</nv-show>
        <nv-show cols="12" sm="3" header="Match Type">
          {{ item.match_type | humanize }}
        </nv-show>
        <nv-show cols="12" sm="1" header="Country">{{ item.country | uppercase }}</nv-show>
        <nv-show cols="12" sm="6" header="App Name">{{ item.app_name }}</nv-show>
        <nv-show cols="12" sm="2" header="Application">
          <router-link
            v-if="application"
            :to="{ name: 'owl.applications.show', params: { appId: item.app_dictionary_id } }"
          >
            {{ application.name }}
          </router-link>
          <span v-else>Unavailable</span>
        </nv-show>
        <nv-show cols="12" sm="3" header="Updated By">{{ item.updated_by }}</nv-show>
        <nv-show cols="12" sm="1" header="Status">{{ item.status | humanize }}</nv-show>
      </v-row>
    </v-card>
    <v-card class="mt-4 elevation-2" data-cy="tabs-card">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="history-tab">
          History
        </v-tab>
        <v-tab data-cy="history-tab">
          User Agents
        </v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id != null" v-model="tabs">
        <v-tab-item>
          <rules-history-tab
            :id="item.id"
            :key="JSON.stringify(item)"
            product="ios"
            data-cy="history-pane"
          />
        </v-tab-item>
        <v-tab-item>
          <user-agents-tab :rule-id="item.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <rules-edit-form v-model="form.show" :editing="form.edit" @saved="fetchData" />
  </nv-page>
</template>

<script>
import HTTP from '@/api/ant.js';
import SEARCH from '@/api/owl-search.js';
import RulesHistoryTab from '@/components/cls/RulesHistoryTab.vue';
import RulesEditForm from '@/components/cls/ios/RulesEditForm.vue';
import UserAgentsTab from '@/components/mv/applications/UserAgentsTab.vue';

export default {
  components: { UserAgentsTab, RulesEditForm, RulesHistoryTab },
  data: () => ({
    item: {},
    tabs: 0,
    form: {
      show: false,
      edit: null,
    },
    application: null,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/ios/rules/${this.$route.params.ruleId}`).then(({ data }) => {
        this.item = data;
        if (data) {
          SEARCH.get('/apps/find', {
            params: {
              id: data.app_dictionary_id,
            },
          }).then(res => {
            this.application = res.data[0];
          });
        }
      });
    },
    openForm(edit) {
      this.form.edit = edit;
      this.form.show = true;
    },
  },
};
</script>
