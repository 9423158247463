<template>
  <nv-tab-page>
    <template #toolbar>
      <nv-btn
        v-if="$auth.hasPermissions('owl.marketview') && canAdd"
        type="add"
        data-cy="add-channel-btn"
        @click="openForm()"
      />
      <v-spacer />
      <nv-table-search v-model="search" />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      class="elevation-1"
      must-sort
    >
      <template #item.name="{ item }">
        <router-link :to="mvChannelPath(item.id)">
          {{ item.name }}
        </router-link>
      </template>
      <template #item.status="{ item }">
        {{ item.status | humanize }}
      </template>
    </v-data-table>

    <brand-channel-form
      v-model="form.show"
      channel
      :post-endpoint="postEndpoint"
      @saved="tableFetch"
    />
  </nv-tab-page>
</template>

<script>
import HTTP from '@/api/owl';
import BrandChannelForm from '@/components/mv/BrandChannelForm';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import FilterSheet from '@/components/mv/brand/FilterSheet';

const headers = [
  { text: 'ID', sortable: true, value: 'id' },
  { text: 'Name', sortable: true, value: 'name' },
  { text: 'Status', sortable: false, value: 'status' },
];

export default {
  components: { FilterSheet, BrandChannelForm },
  mixins: [TableMixin],
  props: {
    value: { type: Number, required: true },
    canAdd: { type: Boolean, required: true },
  },
  data: () => ({
    headers,
    form: {
      show: false,
    },
    tableParam: 'chan.dt',
    searchParam: 'chan.q',
    postEndpoint: undefined,
    table: {
      sortBy: ['name'],
      sortDesc: [false],
    },
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, `/brands/${this.value}/channels`);
    },
    mvChannelPath(childId) {
      return { name: 'owl.channels.show', params: { childId } };
    },
    openForm() {
      this.postEndpoint = `/brand/${this.value}/channel`;
      this.form.show = true;
    },
  },
};
</script>
