<template>
  <v-dialog :value="value" persistent max-width="900px">
    <v-card v-if="item != null" data-cy="mv-review-form">
      <v-card-title class="d-block">
        <span class="text-h5">Review Proposal</span>
      </v-card-title>
      <v-card-text>
        <v-row class="my-0">
          <nv-show cols="12" sm="3" header="Proposal">
            {{ formatTitle(item) | humanize }}
          </nv-show>
          <nv-show cols="12" sm="4" header="Name">
            <router-link v-if="isShowLink" :to="showLink(item.item_kind)">
              {{ item.name }} (#{{ item.item.id }})
            </router-link>
            <span v-else>{{ item.name }}</span>
          </nv-show>
          <nv-show cols="12" sm="5" header="Created By">
            {{ item.created_by }}
          </nv-show>
        </v-row>
        <v-row class="my-0">
          <nv-show v-if="item.reason" cols="12" sm="3" header="Reason">
            {{ item.reason }}
          </nv-show>
          <template v-if="item.reviewed_by || item.status === 'dismissed'">
            <nv-show :offset-sm="item.reason ? 0 : 3" cols="12" sm="4" header="Status">
              {{ item.status | humanize }}
            </nv-show>
            <nv-show cols="12" sm="5" header="Reviewed By">
              {{ item.reviewed_by }}
            </nv-show>
            <nv-show v-if="item.review_comment" cols="12" sm="12" header="Comment">
              {{ item.review_comment }}
            </nv-show>
          </template>
        </v-row>
        <nv-form-errors :errors="formErrors" />
        <v-divider />
        <update-simple-table v-if="item.action === 'update'" :proposal="item" />
        <transfer-proposal
          v-else-if="item.action === 'transfer'"
          :item-id="item.item.id"
          :target-id="item.target.id"
          :proposed="item.proposed_attributes"
        />
        <template v-else>
          <tree-show v-if="item.item_kind === 'tree'" :parent="item.item" />
          <app-proposal
            v-if="item.item_kind === 'application'"
            :proposed="item.proposed_attributes"
          />
          <c6-proposal
            v-if="item.item_kind === 'c6'"
            :proposed="item.proposed_attributes"
            :child-id="item.mv_child_id"
          />
          <localization-rules
            v-if="item.item_kind === 'localization_rule'"
            :proposed="item.proposed_attributes"
          />
          <cid-proposal v-if="item.item_kind === 'cid'" :proposed="item.proposed_attributes" />
          <brand-channel-proposal
            v-if="item.item_kind === 'brand' || item.item_kind === 'channel'"
            :proposed="item.proposed_attributes"
          />
        </template>
        <v-form ref="form">
          <v-row
            v-if="canDo('review') && $auth.hasPermissions('owl.review')"
            class="pt-6 my-0"
            align="center"
            no-gutters
          >
            <v-col cols="12">
              <span class="text-subtitle-1">Review:</span>
            </v-col>
            <v-col cols="auto" class="pr-6">
              <v-btn-toggle v-model="item.status" mandatory>
                <v-btn v-for="o in options" :key="o.value" :value="o.value" text small>
                  {{ o.text }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.review_comment"
                :rules="item.status === 'rejected' ? rules.comment : []"
                validate-on-blur
                label="Comment"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <nv-show cols="5" sm="5" header="Updated At">
          {{ item.updated_at | time }}
        </nv-show>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="formCancel($refs.form)">Close</v-btn>
        <v-btn
          v-if="canDo('review') && $auth.hasPermissions('owl.review')"
          :loading="loading"
          :disabled="loading"
          color="secondary"
          @click="formSubmit($refs.form)"
        >
          Submit
          <template #loader>
            <nv-circular-loader />
          </template>
        </v-btn>
        <v-btn
          v-if="canDo('update')"
          :to="{ name: 'owl.mv.proposals.tree.show', params: { treeId: id } }"
          color="secondary"
        >
          Edit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import TreeShow from '@/components/mv/proposals/TreeShow';
import AppProposal from '@/components/mv/proposals/AppProposal';
import C6Proposal from '@/components/mv/proposals/C6Proposal';
import proposalMixin from '@/helpers/proposals';
import UpdateSimpleTable from '@/components/UpdateSimpleTable';
import BrandChannelProposal from '@/components/mv/proposals/BrandChannelProposal';
import TransferProposal from '@/components/mv/proposals/TransferProposal';
import LocalizationRules from '@/components/mv/proposals/LocalizationRules.vue';
import CidProposal from '@/components/mv/proposals/CidProposal.vue';

export default {
  components: {
    CidProposal,
    LocalizationRules,
    TransferProposal,
    BrandChannelProposal,
    UpdateSimpleTable,
    C6Proposal,
    AppProposal,
    TreeShow,
  },
  mixins: [FormMixin, proposalMixin],
  props: {
    value: { type: Boolean, default: false },
    namespace: { type: String, default: null },
    id: { type: Number, default: null },
    link: { type: Boolean, default: false },
  },
  computed: {
    isShowLink() {
      const kinds = ['parent', 'brand', 'channel', 'application'];
      return this.link && this.item.action === 'update' && kinds.includes(this.item.item_kind);
    },
  },
  methods: {
    async formInit() {
      if (this.id) {
        try {
          const { data } = await HTTP.get(`/${this.namespace}/${this.id}`);
          return data;
        } catch (err) {
          return {};
        }
      } else {
        return {};
      }
    },
    formSave() {
      return HTTP.put(`/${this.item.namespace}/${this.item.id}/review`, this.item, {
        niSkipFlash: true,
      })
        .then(({ data }) => {
          this.$flash('success', `Proposal ${data.name} has been successfully ${data.status}`);
        })
        .catch(err => {
          this.formErrorHandler(err);
        });
    },
    showLink(itemKind) {
      let routerParam;
      switch (itemKind) {
        case 'application':
          routerParam = 'appId';
          break;
        case 'parent':
          routerParam = 'parentId';
          break;
        default:
          routerParam = 'childId';
      }
      return {
        name: `owl.${itemKind}s.show`,
        params: { [routerParam]: this.item.item?.id },
      };
    },
  },
};
</script>
