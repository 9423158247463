<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="3">
      <rules-status-selector v-model="filters.status" product="android" filter />
    </v-col>
    <v-col cols="12" sm="3">
      <updated-by-selector v-model="filters.updated_by" />
    </v-col>
  </v-row>
</template>

<script>
import { FiltersMixin } from '@nielsen-media/ui-toolkit-vue';
import RulesStatusSelector from '@/components/cls/RulesStatusSelector.vue';
import UpdatedBySelector from '@/components/cls/UpdatedBySelector.vue';

export default {
  components: { UpdatedBySelector, RulesStatusSelector },
  mixins: [FiltersMixin],
  data: () => ({
    filters: {
      status: 'active',
      updated_by: null,
    },
  }),
};
</script>
