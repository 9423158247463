<template>
  <v-dialog :value="value" persistent max-width="900px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      data-cy="bulk-domains-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-textarea
            ref="pattern"
            v-model="patterns"
            :rules="rules.patterns"
            data-cy="patterns"
            label="Patterns"
            rows="2"
            auto-grow
            hint="Up to 200 domains separated by commas and/or whitespaces"
          />
        </v-col>
        <v-col cols="12">
          <owl-date-picker v-model="item.effective_start_date" label="Start Date" clearable />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import OwlDatePicker from '@/components/OwlDatePicker.vue';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/ant.js';
import Rules from 'bsm-validation-rules';

export const rules = {
  patterns: [Rules.presence(), Rules.format(/^([\w-]+(\.[\w\-/?#=*$%&:\\]+)+(,?\s*)?)+$/)],
};

export default {
  name: 'BulkDomainForm',
  components: { OwlDatePicker },
  mixins: [FormMixin],
  props: {
    treeId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    formContext: 'Domains',
    rules,
    patterns: null,
  }),
  methods: {
    formInit() {
      return { tree_id: this.treeId };
    },
    formSave() {
      this.item.patterns = this.patterns.split(/[,\s]\s*/);
      return this.submitForm(HTTP, '/domain/rules/bulk', {
        success: ({ data }) => {
          this.$flash('success', `${data.name} domain ${this.formSuccessMsg}`);
        },
      });
    },
  },
};
</script>
