<template>
  <v-list-item v-if="tree.id" dense class="pl-0">
    <search-list-avatar :type="tree.type" class="my-0" />
    <search-list-content :item="tree" link class="py-0" />
  </v-list-item>
  <span v-else-if="treeId && !hideId">{{ treeId }}</span>
</template>

<script>
import SearchListAvatar from '@/components/mv/SearchListAvatar.vue';
import SearchListContent from '@/components/mv/SearchListContent.vue';
import SEARCH from '@/api/owl-search.js';

export default {
  name: 'TreeItem',
  components: { SearchListContent, SearchListAvatar },
  props: {
    treeId: { type: Number, default: null },
    item: { type: Object, default: null },
    hideId: { type: Boolean, default: false },
  },
  data: () => ({
    tree: {},
  }),
  watch: {
    item(val) {
      this.tree = val;
    },
    treeId(val) {
      if (val) {
        this.fetchTree();
      }
    },
  },
  created() {
    if (this.item) {
      this.tree = this.item;
    } else if (this.treeId) {
      this.fetchTree();
    }
  },
  methods: {
    fetchTree() {
      SEARCH.get('/tree/find', {
        params: {
          id: this.treeId,
        },
      }).then(({ data }) => {
        this.tree = data[0];
      });
    },
  },
};
</script>
