<template>
  <nv-page :header="item.name" data-cy="app-details">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn v-if="$auth.hasPermissions('owl.marketview')" type="edit" @click="openForm(item)" />
    </template>
    <v-card class="pa-4" data-cy="details-card">
      <v-row dense>
        <nv-show ref="name" cols="12" sm="3" header="Name">
          {{ item.name }}
        </nv-show>
        <nv-show ref="child" cols="12" sm="3" header="Child">
          <router-link v-if="item.mv_child" :to="showChildLink(item.mv_child)">
            {{ item.mv_child.name }}
          </router-link>
        </nv-show>
        <nv-show ref="status" cols="12" sm="3" header="Status">
          {{ item.status | humanize }}
        </nv-show>
        <nv-show ref="start_date" cols="12" sm="3" header="Start Date">
          {{ item.start_date | date }}
        </nv-show>
        <nv-show ref="reporting_type" cols="12" sm="3" header="Reporting Type">
          {{ item.reporting_type | humanize }}
        </nv-show>
        <nv-show ref="app_function" cols="12" sm="3" header="App Function">
          {{ item.app_function | humanize }}
        </nv-show>
        <nv-show ref="system_type" cols="12" sm="3" header="System Type">
          {{ item.system_type | humanize }}
        </nv-show>
        <nv-show ref="pre_installed" cols="12" sm="3" header="Pre Installed">
          {{ item.pre_installed | humanize }}
        </nv-show>
      </v-row>
    </v-card>

    <v-card class="mt-4 elevation-2" data-cy="tabs-card">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="translation-tab">
          Relabel
        </v-tab>
        <v-tab data-cy="history-tab">
          History
        </v-tab>
        <v-tab data-cy="patterns-tab">
          Rules
        </v-tab>
        <v-tab data-cy="ua-tab">
          User Agents
        </v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id != null" v-model="tabs">
        <v-tab-item data-cy="translation-pane">
          <translation-tab-list :id="item.id" ref="translation-tab-list" />
        </v-tab-item>
        <v-tab-item data-cy="history-pane">
          <proposals-history :id="item.id" type="app/dictionary" />
        </v-tab-item>
        <v-tab-item data-cy="patterns-pane">
          <patterns-tab :id="item.id" kind="app_dictionary" />
        </v-tab-item>
        <v-tab-item data-cy="ua-pane">
          <user-agents-tab :app-id="item.id" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <apps-dictionary-form
      v-if="item.mv_child_id"
      v-model="form.show"
      :editing="form.edit"
      :child-id="item.mv_child_id"
      @saved="fetchData"
    />
  </nv-page>
</template>

<script>
import HTTP from '@/api/owl';
import AppsDictionaryForm from '@/components/mv/AppsDictionaryForm';
import TranslationTabList from '@/components/mv/applications/TranslationTabList';
import PatternsTab from '@/components/mv/PatternsTab';
import ProposalsHistory from '@/components/mv/ProposalsHistory';
import UserAgentsTab from '@/components/mv/applications/UserAgentsTab';

export default {
  components: {
    UserAgentsTab,
    ProposalsHistory,
    PatternsTab,
    AppsDictionaryForm,
    TranslationTabList,
  },
  data: () => ({
    item: {},
    tabs: 0,
    form: {
      show: false,
      edit: null,
    },
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/app/dictionary/${this.$route.params.appId}`).then(res => {
        this.item = res.data;
      });
    },
    openForm(edit) {
      this.form.edit = edit;
      this.form.show = true;
    },
    showChildLink(child) {
      return {
        name: `owl.${child.type}s.show`,
        params: {
          childId: child.id,
        },
      };
    },
  },
};
</script>
