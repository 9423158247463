<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      v-if="item != null"
      :errors="formErrors"
      :title="formTitle"
      :loading="loading"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row v-if="editing">
        <v-col cols="12">
          <tree-search v-if="item.mv_child_id" v-model="item.mv_child_id" mode="classification" />
        </v-col>
        <v-col cols="12">
          <status-selector v-model="item.status" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-select
            v-model="item.cid"
            :items="cids"
            :item-text="cidText"
            :item-value="cidText"
            :rules="rules.cid"
            chips
            label="Client ID"
            data-cy="select-cid"
          />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/owl';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import TreeSearch from '@/components/mv/TreeSearch';
import StatusSelector from '@/components/mv/StatusSelector';
import Rules from 'bsm-validation-rules';

const rules = {
  cid: [Rules.presence()],
};

export default {
  components: { StatusSelector, TreeSearch },
  mixins: [FormMixin],
  props: {
    cids: {
      type: Array,
      default: () => [],
    },
    channelId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    formContext: 'VCID',
    rules,
  }),
  methods: {
    formSave() {
      const path = this.editing ? `/c6s` : `/channels/${this.channelId}/c6s`;
      const msg = this.editing ? 'Update' : 'Create';
      return this.submitForm(HTTP, path, {
        success: () => {
          this.$flash('success', `${msg} VCID Proposal successfully created`);
        },
      });
    },
    cidText(item) {
      return item.country_code + '-' + item.client_id;
    },
  },
};
</script>
