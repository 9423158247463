<template>
  <v-autocomplete
    ref="app-search"
    v-model="selected"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :clearable="inline"
    :solo="inline"
    :flat="inline"
    :hide-details="inline && 'auto'"
    :rules="rules"
    :disabled="disabled"
    prepend-inner-icon="mdi-apps"
    item-text="name"
    return-object
    placeholder="Search"
    hide-no-data
    data-cy="apps-search"
    @change="change"
  >
    <template #item="{ item }">
      <app-dict-search-list-content :app="item" />
    </template>
  </v-autocomplete>
</template>

<script>
import HTTP from '@/api/owl-search';
import { debounce } from 'lodash';
import AppDictSearchListContent from '@/components/mv/AppDictSearchListContent';

function updateSearch(val) {
  if (val) {
    if (!this.selected || val !== this.selected.name) {
      this.fetchData(val);
    }
  }
}

export default {
  components: { AppDictSearchListContent },
  props: {
    value: {
      type: Number,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
    search: '',
    loading: false,
    selected: null,
  }),
  watch: {
    search: debounce(updateSearch, 500),
  },
  async created() {
    if (this.value) {
      this.selected = await this.findApp(this.value);
    }
  },
  methods: {
    findApp(id) {
      this.loading = true;
      return HTTP.get('/apps/find', { params: { id } })
        .then(({ data }) => {
          this.items = data;
          return this.items[0];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchData(search) {
      this.loading = true;
      const params = {
        q: search,
      };
      return HTTP.get('/apps/search', { params })
        .then(({ data }) => {
          if (params.q === this.search) {
            this.items = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change(val) {
      const id = val ? val.id : null;
      this.$emit('input', id);
    },
  },
};
</script>
