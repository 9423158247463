<template>
  <nv-page header="Domain Rule">
    <template slot="toolbar">
      <v-spacer />
      <nv-btn type="back" />
      <nv-btn v-if="$auth.hasPermissions('ant.discovery')" type="edit" @click="openForm(item)" />
    </template>
    <v-card class="pa-4" data-cy="domain-rule-details">
      <v-row>
        <nv-show cols="12" sm="6" header="Rule">{{ item.pattern }}</nv-show>
        <nv-show cols="12" sm="6" no-placeholder header="Level">
          <tree-item :tree-id="item.tree_id" />
        </nv-show>
        <nv-show cols="12" sm="6" header="Updated By">{{ item.updated_by }}</nv-show>
        <nv-show cols="12" sm="3" header="Status">
          {{ item.status | enumLabel(domainStatuses) }}
        </nv-show>
        <nv-show cols="12" sm="3" header="State">
          {{ item.state | enumLabel(domainStates) }}
        </nv-show>
      </v-row>
    </v-card>
    <v-card class="mt-4 elevation-2" data-cy="tabs-card">
      <v-tabs v-model="tabs" slider-color="accent">
        <v-tab data-cy="history-tab">
          History
        </v-tab>
      </v-tabs>
      <v-tabs-items v-if="item.id != null" v-model="tabs">
        <v-tab-item>
          <rules-history-tab
            :id="item.id"
            :key="JSON.stringify(item)"
            product="domain"
            data-cy="history-pane"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <rules-edit-form
      v-model="form.show"
      :editing="form.edit"
      :states="domainStates"
      @saved="fetchData"
    />
  </nv-page>
</template>

<script>
import HTTP from '@/api/ant.js';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import TreeItem from '@/components/cls/TreeItem.vue';
import RulesHistoryTab from '@/components/cls/RulesHistoryTab.vue';
import RulesEditForm from '@/components/cls/domain/RulesEditForm.vue';

export default {
  components: { RulesEditForm, RulesHistoryTab, TreeItem },
  data: () => ({
    item: {},
    tabs: 0,
    form: {
      show: false,
      edit: null,
    },
    domainStatuses: [],
    domainStates: [],
  }),
  created() {
    this.enumFetch(HTTP, {
      domainStatuses: '/domain/rules/enum/statuses',
      domainStates: '/domain/rules/enum/states',
    });
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/domain/rules/${this.$route.params.ruleId}`).then(({ data }) => {
        this.item = data;
      });
    },
    openForm(edit) {
      this.form.edit = edit;
      this.form.show = true;
    },
    enumFetch,
  },
};
</script>
