<template>
  <v-dialog :value="value" persistent max-width="700px" scrollable>
    <nv-form title="User Agents" save-text="OK" @cancel="formCancel" @nv-submit="formCancel">
      <v-simple-table>
        <tbody>
          <tr v-for="ua in userAgents" :key="ua.id">
            <td class="text-break">{{ cleanseUserAgent(ua.user_agent) }}</td>
            <td>{{ ua.domain }}</td>
          </tr>
          <tr v-if="!userAgents.length">
            <td>
              <v-progress-linear v-if="loading" indeterminate />
              <span v-else>No matching user agents found</span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue';
import { cleanseUserAgent } from '../../../helpers/cleanseData';

export default {
  mixins: [FormMixin],
  props: {
    matchId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    userAgents: [],
    loading: false,
  }),
  methods: {
    formReady() {
      this.userAgents = [];
      if (this.matchId) {
        this.loading = true;
        HTTP.get('/ios/user_agents', {
          params: { filter: { rule_id: this.matchId }, per_page: 100 },
        })
          .then(({ data }) => {
            this.userAgents = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    cleanseUserAgent(userAgent) {
      return cleanseUserAgent(userAgent);
    },
  },
};
</script>
