<template>
  <v-btn
    ref="save-btn"
    color="secondary"
    :loading="loading"
    :disabled="loading"
    small
    @click="$emit('click', $event)"
  >
    {{ saveText }}
    <template #loader>
      <nv-circular-loader />
    </template>
  </v-btn>
</template>

<script>
export default {
  props: {
    loading: { type: Boolean, required: true },
    saveText: { type: String, default: 'Save' },
  },
};
</script>
