import HTTP from '@/api/ant';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';

export default function(type) {
  return {
    mixins: [TableMixin],
    data: () => ({
      tableFilters: {
        status: 'locked',
      },
      createForm: {
        show: false,
      },
    }),
    methods: {
      async tableFetch() {
        const { data } = await this.paginateTableData(HTTP, `/${type}/discoveries`);
        if (!data.length && !this.search) {
          const key = type.replace('/', '.');
          this.$router.push({ name: `owl.${key}.discovery.list` });
        }
      },
      unlock() {
        const path = type === 'video' ? '/video/domains' : `/${type}/discoveries`;
        if (localStorage.getItem(`${type}_sort_by`)) {
          localStorage.removeItem(`${type}_sort_by`);
        }
        if (localStorage.getItem(`${type}_sort_desc`)) {
          localStorage.removeItem(`${type}_sort_desc`);
        }
        HTTP.put(`${path}/unlock`)
          .then(() => {
            this.$flash('success', `Successfully unlocked`);
            let key = type.replace('/', '.');
            this.$router.push({ name: `owl.${key}.discovery.list` });
          })
          .catch(err => {
            this.$flash('error', err.toString());
          });
      },
    },
  };
}
