<template>
  <v-row class="my-0">
    <nv-show cols="12" sm="3" header="Code">
      {{ proposed.code }}
    </nv-show>
    <nv-show cols="12" sm="4" header="cid">
      {{ proposed.cid }}
    </nv-show>
    <nv-show cols="12" sm="5" header="Channel" no-placeholder>
      <tree-item :tree-id="childId" />
    </nv-show>
  </v-row>
</template>

<script>
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  components: { TreeItem },
  props: {
    proposed: {
      type: Object,
      required: true,
    },
    childId: {
      type: Number,
      required: true,
    },
  },
};
</script>
