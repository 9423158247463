<template>
  <v-select
    ref="adSupported"
    :value="value"
    :items="adSupported"
    :rules="rules.adSupported"
    label="Ad Supported"
    data-cy="ad-supported"
    @change="$emit('input', $event)"
  />
</template>

<script>
import HTTP from '@/api/owl';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import Rules from 'bsm-validation-rules';

const rules = {
  adSupported: [Rules.presence()],
};

export default {
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    adSupported: [],
    rules,
  }),
  created() {
    this.enumFetch(HTTP, {
      adSupported: '/enum/ad_supported',
    });
  },
  methods: {
    enumFetch,
  },
};
</script>
