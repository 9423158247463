<template>
  <v-dialog :value="value" persistent max-width="600px">
    <nv-form
      :errors="formErrors"
      :loading="loading"
      title="Transfer User Agents (Scoped)"
      data-cy="transfer-user-agents-form"
      @cancel="formCancel"
      @nv-submit="formSubmit"
    >
      <v-row>
        <v-col v-if="value" cols="12">
          <v-text-field
            ref="user_agent"
            v-model="item.user_agent"
            data-cy="user_agent"
            label="Search"
            disabled
          />
        </v-col>
        <v-col v-if="value" cols="12">
          <v-text-field
            ref="domain"
            v-model="item.domain"
            data-cy="domain"
            label="Domain"
            disabled
          />
        </v-col>
        <v-col v-if="value" cols="12">
          <app-dict-search v-model="item.target_id" :rules="rules.targetId" />
        </v-col>
      </v-row>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue-api';
import AppDictSearch from '@/components/mv/AppDictSearch';
import Rules from 'bsm-validation-rules';

const rules = {
  targetId: [Rules.presence()],
};

export default {
  components: { AppDictSearch },
  mixins: [FormMixin],
  props: {
    childId: {
      type: Number,
      required: true,
    },
    userAgent: {
      type: String,
      default: null,
    },
    domain: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    rules,
  }),
  methods: {
    formInit() {
      return {
        target_id: null,
        domain: this.domain,
        user_agent: this.userAgent,
        source_id: this.childId,
      };
    },
    formSave() {
      return this.submitForm(HTTP, `/ios/user_agents/scope/transfer`, {
        success() {
          this.$flash('success', `Transfer Proposal successfully created`);
        },
      });
    },
  },
};
</script>
