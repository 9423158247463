<template>
  <v-list-item-avatar class="ml-n2">
    <v-icon large>{{ `mdi-alpha-${type[0]}-box-outline` }}</v-icon>
  </v-list-item-avatar>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
