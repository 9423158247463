<template>
  <v-row class="my-0">
    <nv-show cols="12" sm="4" header="Transfer Patterns">
      <div>
        <div
          v-for="pattern in proposal.transfer_resources"
          :key="pattern.id"
          class="text-body-2 my-1"
        >
          {{ pattern.pattern }}
        </div>
      </div>
    </nv-show>
    <nv-show cols="12" sm="4" header="From">
      <v-list-item v-if="item.id" dense class="pl-0">
        <app-dict-search-list-content v-if="isApp" :app="item" link />
        <template v-else>
          <search-list-avatar :type="item.type" class="my-0" />
          <search-list-content :item="item" link class="py-0" />
        </template>
      </v-list-item>
      <kbd v-else-if="itemId">{{ itemId }}</kbd>
    </nv-show>
    <nv-show cols="12" sm="4" header="To">
      <v-list-item v-if="target.id" dense class="pl-0">
        <app-dict-search-list-content v-if="isApp" :app="target" link />
        <template v-else>
          <search-list-avatar :type="target.type" class="my-0" />
          <search-list-content :item="target" link class="py-0" />
        </template>
      </v-list-item>
      <kbd v-else-if="targetId">{{ targetId }}</kbd>
    </nv-show>
  </v-row>
</template>

<script>
import SEARCH from '@/api/owl-search';
import SearchListAvatar from '@/components/mv/SearchListAvatar';
import SearchListContent from '@/components/mv/SearchListContent';
import AppDictSearchListContent from '@/components/mv/AppDictSearchListContent';

export default {
  components: { AppDictSearchListContent, SearchListContent, SearchListAvatar },
  props: {
    proposal: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    item: {},
    target: {},
  }),
  computed: {
    itemId() {
      return this.proposal.proposed_attributes.source_id;
    },
    isApp() {
      return this.proposal.namespace.includes('android') || this.proposal.namespace.includes('ios');
    },
    targetId() {
      return this.proposal.proposed_attributes.target_id;
    },
  },
  created() {
    this.fetchSearch();
  },
  methods: {
    fetchSearch() {
      const url = this.isApp ? '/apps/find' : '/tree/find';
      const ids = [];
      if (this.targetId) {
        ids.push(this.targetId);
      }
      if (this.itemId) {
        ids.push(this.itemId);
      }
      if (ids.length) {
        SEARCH.get(url, {
          params: {
            id: ids.join(','),
          },
        }).then(({ data }) => {
          this.item = data.find(el => el.id === this.itemId) || {};
          this.target = data.find(el => el.id === this.targetId) || {};
        });
      }
    },
  },
};
</script>
