<template>
  <nv-page header="Classify Connected Devices" data-cy="ottm-classify">
    <template #toolbar>
      <v-spacer />
      <v-btn
        v-if="$auth.hasPermissions('owl.marketview')"
        color="secondary"
        @click="createForm.show = true"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>
        <span>Create Channel</span>
      </v-btn>
    </template>

    <v-simple-table :key="count">
      <thead>
        <tr>
          <th colspan="4">
            <v-row>
              <v-col cols="4">Pattern</v-col>
              <v-col cols="4">Tree ID</v-col>
              <v-col cols="2">Property</v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <classify-row v-for="i in 10" :key="i" />
        <tr>
          <td colspan="4">
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn color="accent" @click="count++">
                  <v-icon class="mr-1">mdi-refresh</v-icon>
                  <span>Clear</span>
                </v-btn>
              </v-col>
            </v-row>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <brand-channel-form v-model="createForm.show" channel cls />
  </nv-page>
</template>

<script>
import ClassifyRow from '@/components/cls/connected-devices/ClassifyRow';
import BrandChannelForm from '@/components/mv/BrandChannelForm';

export default {
  components: { BrandChannelForm, ClassifyRow },
  data: () => ({
    createForm: { show: false },
    count: 0,
  }),
};
</script>
