<template>
  <tr>
    <td v-if="!mv && showSelect">
      <slot name="select"></slot>
    </td>
    <td>
      <a class="text-decoration-underline" @click="$emit('open-form', item)">
        {{ formatTitle(item) | humanize }}
      </a>
    </td>
    <td v-if="!hideName">
      <a class="text-decoration-underline text-break" @click="$emit('open-form', item)">
        {{ item.name }}
      </a>
    </td>
    <td v-if="!mv" style="max-width: 300px;">
      <tree-item v-if="item.channel && item.channel.id" :item="item.channel" />
      <router-link
        v-else-if="item.application"
        :to="{ name: 'owl.applications.show', params: { appId: item.app_dictionary_id } }"
        class="text-subtitle-1"
      >
        {{ item.application.name }}
      </router-link>
      <span v-else>Unavailable</span>
    </td>
    <td v-if="video">
      <v-btn
        v-if="item.namespace === 'video/proposals/rule'"
        icon
        @click="$emit('open-discoveries', item.id)"
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </td>
    <td v-if="ios">{{ formatIosFields('match_type') | humanize }}</td>
    <td v-if="ios">{{ formatIosFields('domain') }}</td>
    <td v-if="ios">{{ formatIosFields('country') | uppercase }}</td>
    <td>{{ item.status | humanize }}</td>
    <td>{{ item.created_by }}</td>
    <td v-if="showChanges">
      <ul class="py-4 ml-n3">
        <li v-for="(val, key) in item.previous_attributes" :key="key">
          <span class="font-weight-bold mr-2">{{ key | humanize }}:</span>
          <template v-if="key === 'sub_category_id'">
            <category-show :subcategory-id="val" />
            <span class="mx-1">&rarr;</span>
            <category-show :subcategory-id="item.proposed_attributes[key]" />
          </template>
          <changelog-item
            v-else
            :before="JSON.stringify(val)"
            :after="JSON.stringify(item.proposed_attributes[key])"
          />
        </li>
      </ul>
    </td>
    <td>{{ item.updated_at | time }}</td>
  </tr>
</template>

<script>
import { formatTitle } from '@/helpers/proposals';
import ChangelogItem from '@/components/ChangelogItem';
import CategoryShow from '@/components/mv/proposals/CategoryShow.vue';
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  components: { TreeItem, CategoryShow, ChangelogItem },
  props: {
    item: {
      type: Object,
      required: true,
    },
    mv: {
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    showChanges: {
      type: Boolean,
      default: false,
    },
    ios: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatTitle,
    formatIosFields(key) {
      return this.item.proposed_attributes?.[key] || this.item.item?.[key];
    },
  },
};
</script>
