<template>
  <nv-page header="Video Discovery" data-cy="video-discovery">
    <template #toolbar>
      <v-spacer />
      <nv-table-search v-model="search" sync class="ml-4" data-cy="search" />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="formattedFilters"
        :no-clear="isDefaultFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      must-sort
    >
      <template #item="{ item }">
        <discovery-row :item="item" data-cy="video-discovery-row" />
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import FilterSheet from '@/components/cls/FilterSheet';
import DiscoveryList from '@/components/cls/DiscoveryList';
import DiscoveryRow from '@/components/cls/video/DiscoveryRow';

const headers = [
  { text: 'Domain', sortable: false, value: 'domain' },
  { text: 'Audience', sortable: false, value: 'rank' },
  { text: 'Last Seen', sortable: false, value: 'last_seen_on' },
  { text: '', sortable: false, align: 'end', value: 'lock' },
];

export default {
  components: { DiscoveryRow, FilterSheet },
  extends: DiscoveryList('video'),
  data: () => ({
    headers,
  }),
};
</script>
