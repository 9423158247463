<template>
  <v-row class="h-100 flex-md-nowrap" no-gutters data-cy="parent-details">
    <v-col>
      <nv-page :header="item.name">
        <template slot="toolbar">
          <v-spacer />
          <nv-btn type="back" />
          <nv-btn
            v-if="$auth.hasPermissions('owl.marketview')"
            type="edit"
            @click="openForm(item)"
          />
        </template>
        <v-card class="pa-4">
          <v-row dense>
            <nv-show cols="12" sm="2" header="Level">Parent</nv-show>
            <nv-show ref="name" cols="12" sm="4" header="Name">
              {{ item.name }}
            </nv-show>
            <nv-show cols="12" sm="2" header="Tree ID">{{ item.id }}</nv-show>
            <nv-show ref="status" cols="12" sm="4" header="Status">
              {{ item.status | humanize }}
            </nv-show>
            <nv-show ref="default-cid" cols="12" sm="12" header="Client IDs" no-placeholder>
              <cid-pane :parent-id="parentId" />
            </nv-show>
          </v-row>
        </v-card>
        <v-card class="mt-4 elevation-2">
          <v-tabs v-model="tabs" slider-color="accent">
            <v-tab data-cy="brands-tab">
              Brands
            </v-tab>
            <v-tab data-cy="history-tab">
              History
            </v-tab>
          </v-tabs>
          <v-tabs-items v-if="item.id != null" v-model="tabs">
            <v-tab-item data-cy="brands-pane">
              <brands-tab-list
                ref="brands-tab-list"
                :value="item.id"
                :can-add="item.status === 'active'"
              />
            </v-tab-item>
            <v-tab-item data-cy="history-pane">
              <proposals-history :id="parentId" ref="history-list" type="parents" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>

        <parent-form v-model="form.show" :editing="form.edit" @saved="fetchData" />
      </nv-page>
    </v-col>
    <tree-navigation :key="item.id" :tree-id="item.id" />
  </v-row>
</template>

<script>
import HTTP from '@/api/owl';
import BrandsTabList from '@/components/mv/parents/BrandsTabList.vue';
import ProposalsHistory from '@/components/mv/ProposalsHistory';
import ParentForm from '@/components/mv/parents/ParentForm';
import TreeNavigation from '@/components/mv/TreeNavigation';
import CidPane from '@/components/mv/parents/CidPane';

export default {
  components: {
    CidPane,
    TreeNavigation,
    ParentForm,
    BrandsTabList,
    ProposalsHistory,
  },
  data: () => ({
    item: {},
    tabs: 0,
    form: {
      show: false,
      edit: null,
    },
  }),
  computed: {
    parentId() {
      return parseInt(this.$route.params.parentId, 10);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      HTTP.get(`/parents/${this.$route.params.parentId}`).then(res => {
        this.item = res.data;
      });
    },
    openForm(edit) {
      this.form.edit = edit;
      this.form.show = true;
    },
  },
};
</script>
