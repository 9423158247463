<template>
  <nv-page header="Classify IOS Bundle" data-cy="ios-bundle-classify">
    <template #toolbar>
      <v-spacer />
      <v-chip v-if="items.length" label outlined>
        Lock expires at {{ items[0].lock_expires_at | time('short') }}
      </v-chip>
      <v-spacer />
      <v-btn
        v-if="$auth.hasPermissions('owl.marketview')"
        color="secondary"
        @click="createForm.show = true"
      >
        <v-icon class="mr-1">mdi-plus</v-icon>
        <span>Create App</span>
      </v-btn>
      <nv-btn v-if="$auth.hasPermissions('ant.discovery')" type="unlock" @click="unlock()" />
    </template>

    <template #filters>
      <nv-table-filters ref="applied-filters" v-model="tableFilters" no-clear />
    </template>

    <v-data-table
      :items="items"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :headers-length="3"
      hide-default-header
    >
      <template #header>
        <thead>
          <tr>
            <th colspan="3">
              <v-row>
                <v-col cols="6">Bundle ID</v-col>
                <v-col cols="4">Application</v-col>
              </v-row>
            </th>
          </tr>
        </thead>
      </template>
      <template #item="{ item }">
        <classify-row :item="item" type="bundle" />
      </template>
    </v-data-table>
    <apps-dictionary-form v-model="createForm.show" />
  </nv-page>
</template>

<script>
import ClassifyList from '@/components/cls/ClassifyList';
import ClassifyRow from '@/components/cls/ios/ClassifyRow';
import AppsDictionaryForm from '@/components/mv/AppsDictionaryForm';

export default {
  components: { AppsDictionaryForm, ClassifyRow },
  extends: ClassifyList('ios/bundle'),
};
</script>
