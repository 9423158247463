import {
  ComponentsPlugin,
  FlashPlugin,
  FiltersPlugin,
  PlaceholderPlugin,
  inflections,
} from '@nielsen-media/ui-toolkit-vue';
import '@nielsen-media/ui-toolkit-vue/lib/ui-toolkit-vue.css';
import Vue from 'vue';

Vue.use(ComponentsPlugin);
Vue.use(FiltersPlugin, { locale: 'en-US' });
Vue.use(PlaceholderPlugin);
Vue.use(FlashPlugin);

inflections('en', i => {
  i.acronym('ID');
  i.acronym('SDK');
});
