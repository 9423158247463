<template>
  <nv-page header="IOS User Agents">
    <template #toolbar>
      <v-spacer />
      <nv-table-search v-model="search" sync />
    </template>

    <template #filters>
      <nv-table-filters
        ref="applied-filters"
        :value="tableFilters"
        @clear="$refs['filter-sheet'].clearFilters()"
      >
        <ua-filter-sheet ref="filter-sheet" @changed="setTableFilters" />
      </nv-table-filters>
    </template>

    <v-data-table
      :headers="headers"
      :items="extendedItems"
      :loading="loading"
      :options.sync="table"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :items-per-page.sync="table.itemsPerPage"
      :expanded.sync="expanded"
      show-expand
      must-sort
    >
      <template #item="{ item, expand, isExpanded }">
        <tr>
          <td class="text-break">{{ cleanseUserAgent(item.user_agent) }}</td>
          <td>
            <router-link
              v-if="item.application"
              :to="{ name: 'owl.applications.show', params: { appId: item.app_dictionary_id } }"
            >
              {{ item.application.name }}
            </router-link>
            <span v-else>Application unavailable</span>
          </td>
          <td>{{ item.domain }}</td>
          <td class="text-break">{{ item.app_name }}</td>
          <td>{{ item.country | uppercase }}</td>
          <td class="text-no-wrap">{{ item.updated_at | date }}</td>
          <td class="pl-0">
            <v-btn
              icon
              small
              class="v-data-table__expand-icon ml-n3 ml-xl-0"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
              @click="expand(!isExpanded)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template #expanded-item="{ item }">
        <ua-expand-row :rule-id="item.rule_id" />
      </template>
    </v-data-table>
  </nv-page>
</template>

<script>
import HTTP from '@/api/ant';
import UaExpandRow from '@/components/cls/ios/UaExpandRow';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import UaFilterSheet from '@/components/cls/ios/UaFilterSheet';
import { fetchAppsSearch } from '@/helpers/owlFind.js';
import { cleanseUserAgent } from '../../../../helpers/cleanseData';

const headers = [
  { text: 'User Agent', sortable: false, value: 'user_agent', width: '50%' },
  {
    text: 'App Dictionary Name',
    sortable: true,
    value: 'app_dictionary_id',
    class: 'text-no-wrap',
  },
  { text: 'Domain', sortable: false, value: 'domain' },
  { text: 'App Name', sortable: false, value: 'app_name', width: '15%' },
  { text: 'Country', sortable: false, value: 'country' },
  { text: 'Updated At', sortable: true, value: 'updated_at', class: 'text-no-wrap' },
  { text: '', value: 'data-table-expand' },
];

export default {
  components: { UaFilterSheet, UaExpandRow },
  mixins: [TableMixin],
  data: () => ({
    table: {
      sortBy: ['updated_at'],
      sortDesc: [true],
    },
    headers,
    extendedItems: [],
    expanded: [],
  }),
  methods: {
    tableFetch() {
      this.paginateTableData(HTTP, '/ios/user_agents')
        .then(async ({ data }) => {
          if (data.length) {
            this.loading = true;
            this.extendedItems = await fetchAppsSearch(data);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cleanseUserAgent(userAgent) {
      return cleanseUserAgent(userAgent);
    },
  },
};
</script>
