<template>
  <v-breadcrumbs :items="items" divider=">" class="pb-1"></v-breadcrumbs>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
