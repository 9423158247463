<template>
  <v-dialog :value="value" persistent max-width="700px" scrollable>
    <nv-form title="Discoveries" save-text="OK" @cancel="formCancel" @nv-submit="formCancel">
      <v-simple-table>
        <tbody>
          <tr v-for="d in discoveries" :key="d.id">
            <td class="text-break">{{ d.media_url }}</td>
          </tr>
          <tr v-if="!discoveries.length">
            <td>
              <v-progress-linear v-if="loading" indeterminate />
              <span v-else>No discoveries found</span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </nv-form>
  </v-dialog>
</template>

<script>
import HTTP from '@/api/ant';
import { FormMixin } from '@nielsen-media/ui-toolkit-vue';

export default {
  mixins: [FormMixin],
  props: {
    proposalId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    discoveries: [],
    loading: false,
  }),
  methods: {
    formReady() {
      this.discoveries = [];
      if (this.proposalId) {
        this.loading = true;
        HTTP.get(`/video/proposals/rule/${this.proposalId}/discoveries`)
          .then(({ data }) => {
            this.discoveries = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
