<template>
  <v-row dense class="my-0">
    <nv-show cols="12" sm="4" header="Name">
      {{ proposed.name }}
    </nv-show>
    <nv-show cols="12" sm="4" header="Status">
      {{ proposed.status | humanize }}
    </nv-show>
    <nv-show header="Tree ID" cols="12" sm="4" no-placeholder>
      <tree-item :tree-id="proposed.mv_child_id" />
    </nv-show>
    <nv-show cols="12" sm="4" header="Reporting type">
      {{ proposed.reporting_type | humanize }}
    </nv-show>
    <nv-show cols="12" sm="4" header="App Function">
      {{ proposed.app_function | humanize }}
    </nv-show>
    <nv-show cols="12" sm="4" header="System type">
      {{ proposed.system_type | humanize }}
    </nv-show>
    <nv-show cols="12" sm="4" header="Pre installed">
      {{ proposed.pre_installed | humanize }}
    </nv-show>
    <nv-show cols="12" sm="4" header="Start Date">
      {{ proposed.start_date }}
    </nv-show>
  </v-row>
</template>

<script>
import TreeItem from '@/components/cls/TreeItem.vue';

export default {
  components: { TreeItem },
  props: {
    proposed: {
      type: Object,
      required: true,
    },
  },
};
</script>
