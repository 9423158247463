<template>
  <v-dialog :value="value" persistent max-width="900px" scrollable>
    <nv-form title="Matching" @cancel="dialogCancel" @nv-submit="saveRule">
      <v-tabs v-model="tabs" slider-color="accent" class="mb-2">
        <v-tab data-cy="discoveries-tab"> Discoveries </v-tab>
        <v-tab v-if="product === 'ios/ua'" data-cy="ua-tab"> User Agents </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item data-cy="discoveries-pane">
          <discovery-matching v-if="value" :domain="domain" :pattern="pattern" :product="product" />
        </v-tab-item>
        <v-tab-item v-if="value" data-cy="ua-pane">
          <ua-matching :pattern="pattern" :domain="domain" />
        </v-tab-item>
      </v-tabs-items>
    </nv-form>
  </v-dialog>
</template>

<script>
import UaMatching from '@/components/cls/ios/UaMatching';
import DiscoveryMatching from '@/components/cls/DiscoveryMatching';

export default {
  components: { DiscoveryMatching, UaMatching },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    domain: {
      type: String,
      default: '',
    },
    pattern: {
      type: String,
      required: true,
    },
    product: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tabs: 0,
  }),
  methods: {
    saveRule() {
      this.$emit('saved');
      this.dialogCancel();
    },
    dialogCancel() {
      this.$emit('input', false);
      this.tabs = 0;
    },
  },
};
</script>
