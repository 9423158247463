import OWL from '@/api/owl';
import { TableMixin } from '@nielsen-media/ui-toolkit-vue-api';
import { formatFilters } from '@/helpers/filters';
import { enumFetch } from '@nielsen-media/ui-toolkit-vue-api';
import HTTP from '@/api/ant';

export default function(type) {
  return {
    mixins: [TableMixin],
    data: () => ({
      countries: [],
      table: {
        sortBy: ['rank'],
        sortDesc: [true],
      },
      tableFilters: {
        status: 'pending',
      },
    }),
    computed: {
      formattedFilters() {
        return formatFilters(this.tableFilters, this.countries, 'country');
      },
      maxHits() {
        return this.items[0]?.hits;
      },
      isDefaultFilters() {
        return Object.values(this.formattedFilters).filter(v => !!v).length === 1;
      },
    },
    created() {
      this.fetchLocked();
      this.enumFetch(OWL, {
        countries: '/countries?per_page=300',
      });
    },
    methods: {
      fetchLocked() {
        const path = type === 'video' ? '/video/domains' : `/${type}/discoveries`;
        HTTP.get(path, {
          params: { filter: { status: 'locked' } },
        }).then(({ data }) => {
          if (data.length > 0) {
            let key = type.replace('/', '.');
            const params = {};
            if (type === 'video') {
              params.domain = data[0].domain;
            }
            this.$router.push({ name: `owl.${key}.classify.list`, params });
          }
        });
      },
      tableFetch() {
        const path = type === 'video' ? '/video/domains' : `/${type}/discoveries`;
        this.paginateTableData(HTTP, path);
      },
      reserve() {
        const params = {};
        if (this.tableFilters.country) {
          params.filter = {
            country: this.tableFilters.country,
          };
        }
        if (this.tableFilters.domain) {
          params.filter = {
            domain: this.tableFilters.domain,
          };
        }
        if (this.search) {
          params.search = this.search;
        }
        if (this.table.page > 1) {
          params.max_hits = this.maxHits;
        }
        if (this.table.sortBy && this.table.sortBy[0]) {
          params.sort = this.table.sortBy[0];
          localStorage.setItem(`${type}_sort_by`, params.sort);
        }
        if (params.sort && this.table.sortDesc && this.table.sortDesc[0]) {
          params.sort = `-${params.sort}`;
          localStorage.setItem(`${type}_sort_desc`, this.table.sortDesc[0]);
        }

        HTTP.put(`/${type}/discoveries/lock`, params).then(({ data }) => {
          if (data.count > 0) {
            this.$flash('success', `Locked ${data.count} items`);
            let key = type.replace('/', '.');
            this.$router.push({ name: `owl.${key}.classify.list` });
          } else {
            this.$flash('warning', `items were not locked`);
          }
        });
      },
      setTableFilters(filters) {
        this.tableFilters = { ...this.tableFilters, ...filters };
      },
      enumFetch,
    },
  };
}
